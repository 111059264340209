import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";

export const Analytics: React.FC = () => {
  return (
    <PrivateRoute>
      <span>Analytics</span>
    </PrivateRoute>
  );
}

export default Analytics;