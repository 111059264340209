import React from 'react';
import { connect } from 'react-redux';
import { createTasks, showWindowBid  } from 'domain/actions/secretShopper.action';
import { Field, withTypes } from "react-final-form";
import { TextField, TextAreaField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

//form
export interface IForm {
  bidId: string;
  name: string;
  description: string;
}

const { Form } = withTypes<IForm>()

interface IProps{
  currentId: string | null;
}

const CreateTaskDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, showWindowBid, createTasks, current } = props;

  const data: IForm = {
    bidId: currentId,
    name: `Кальянная ${current?.restaurant}`,
    description: `Посети кальянное заведение ${current?.restaurant}, закажи себе кальян, и оставь на нашем сайте отзыв об обслуживании. И за это мы вернем тебе 70% от стоимости кальяна `
  };

  const handleClose = () => showWindowBid('createTasks', false);
  const onSubmit = (values: IForm) => createTasks(values);

  return (
    <Dialog header="Создание заданий" visible={visible} style={{ maxWidth: '436px', minWidth: '375px' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              validate={composeValidators(requiredValidator)}
              name="bidId" label="Номер заявки:" render={TextField} settings={{
                disabled: true
              }}
            />
            <div className="p-mt-2 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="name"
                label="Наименование:"
                render={TextField}
                settings={{
                  placeholder: "Заведение ... "
                }}
                icon={["fas", "pen"]}
              />
            </div>

            <div className="p-mt-2 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="description"
                label="Описание:"
                render={TextAreaField}
                settings={{
                  placeholder: "Посети ... ",
                  rows:5,
                  cols:35
                }}
              />
            </div>
            
            <div className="p-mt-1 p-d-flex p-jc-end">
              <Button 
                label="Создать" 
                type="submit" 
                disabled={submitting}
              />
            </div>
          </form>
        )}
      /> 
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.secretShopper.bids.current,
  visible: state.secretShopper.bids.windows.createTasks
})
export default connect(mapStateToProps, { createTasks, showWindowBid } )(CreateTaskDialog);