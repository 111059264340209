import React, { useEffect } from "react";
import { UAParser } from 'ua-parser-js'

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { getNotifications } from "domain/actions/notification.action";

export const SubscribeNotificationPage: React.FC = (props: any) => {
  const { tokens, loading, getNotifications } = props;
  const parser = new UAParser()

  const handleRefresh = () => getNotifications();

  useEffect( () => {
    handleRefresh()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userAgent = (useragent: string) => {
    try {
      const agent = parser.setUA(useragent).getResult()
      return (
        <span >
          {agent.browser.name} - {agent.device.model}
        </span>
      )
    } catch (error) {
      return <span> Не указан </span>
    }
  }

  const nameTemplate = (account: any) => (
    <span >
      <i>{account?.surname ? account?.surname : ""} </i>
      <strong>{account?.name}</strong> 
    </span>
  )

  return (
    <>
      <span className="content-header">Подписки к push-уведомлениям</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
        <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
        </div>
        </div>

        <div className="card">
          <DataTable value={tokens} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column 
              field="account" header="ФИО"
              body={(rowData)=>nameTemplate(rowData.account)}
            ></Column>
            <Column 
              field="useragent" header=""
              body={(rowData)=>userAgent(rowData.token.useragent)}
            ></Column>
            {/* <Column body={action} style={{ maxWidth: '5rem' }}></Column> */}
          </DataTable>
        </div>

      </ScrollPanel>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  tokens: state.notification.subscriptions,
  loading: state.notification.request.loading
})

export default connect(mapStateToProps, { getNotifications })(SubscribeNotificationPage);