import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { 
  getSecretShopperTasks, 
  showWindowTask, 
  continueTask, 
  showWindowModeration,
  deactivateTask 
} from 'domain/actions/secretShopper.action';
import TaskWindow from './task.window';
import ReceiptWindow from './receipt.window';

import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { toStatusTask, toStatusRaffle } from "pages/views/controllers/toStatus";
import ActionsColumn from "pages/views/components/table/action.column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ModerationWindow from "./windows/moderation.window";
import { toDate, toDateNoTime } from "utils/toDate.util";
import DeactivateWin from "./windows/deact.window";
import { useMediaQuery } from "react-responsive";

export const Tasks: React.FC = (props: any) => {
  const { 
    tasks, 
    loading,
    getSecretShopperTasks, 
    showWindowTask,
    showWindowModeration,
    deactivateTask
  } = props;
  const [ deactivate, setDeactivateWindow ] = useState(false);
  const [ currentId, setCurrentId ] = useState<string | null>(null);
  const [ item, setItem ] = useState<any>(null);
  const [ search, setSearch ] = useState<string>("");
  const [ filters, setFilters] = useState<any>({
    'state': { value: null, matchMode: 'equals' },
    'id': {value: null, matchMode: 'contains'}
  });
  const [globalFilterValue, setGlobalFilterValue] = useState(undefined);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => getSecretShopperTasks(), [getSecretShopperTasks])

  const handleDeactivate = (message: string) => {
    deactivateTask(item.id, message);
    setDeactivateWindow(false)
  };
  const handleRefresh = () => getSecretShopperTasks();
  // const handleViewing = () => showWindowTask('views', true);
  const handleReceipt = (id: string) => {
    setCurrentId(id)
    showWindowTask('payment', true)
  };
  const handleQuestionnaire = (id: string) => {
    setCurrentId(id)
    showWindowModeration('views', true)
  };
  const closeViewModeration = () => {
    showWindowModeration('views', false)
    setCurrentId(null)
  }
  const closePaymentModeration = () => {
    showWindowTask('payment', false)
    setCurrentId(null)
  }

  let menu = (item: { id: string, state: string; }) => {
    const menuItems = [
      {
        label: 'Прикрепить чек оплаты', 
        icon: 'pi pi-fw pi-dollar', 
        command:()=> handleReceipt(item.id), 
        disabled: item.state !== "payout" && item.state !== "confirm" 
      }
    ];
    menuItems.push({
      label: 'Проверить анкету', 
      icon: 'pi pi-fw pi-question', 
      command:()=> handleQuestionnaire(item.id), 
      disabled: 
        item.state !== "verification" && 
        item.state !== "confirm" && 
        item.state !== "payout" && 
        item.state !== "verified"
    })

    menuItems.push({
      label: 'Отменить задание', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> {
        setItem(item)
        setDeactivateWindow(true)
      }, 
      disabled: item.state !== "new" && item.state !== "start" && item.state !== "active" 
    })
    return menuItems;
  }

  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />
    
  const toStatusTemplate = (status: string) => (<span className={`task-status task-status-${status}`}>{toStatusTask(status)}</span>)
  const toStatusRaffleTemplate = (status: string) => (<span className={`task-status task-status-${status}`}>{toStatusRaffle(status)}</span>)
  const nameAccountTemplate = (account: any) => (
    <span >
      <strong>{account?.name}</strong> - 
      <i>{account?.surname}</i> 
    </span>
  )
  const nameTemplate = (restaurant: any, raffles: number, counter: number, state: string) => {
    const text = `${raffles}/${counter}`
    const type = raffles === 0 ? 'warning' : raffles === counter ? 'success' : 'info'
    const deleted = restaurant?.deletedAt ? `(Заведение удалено - ${toDate(restaurant.deletedAt)})` : <></>;

    return (
      <span >
        <strong>{restaurant?.name} {deleted}</strong> - 
        <i>{restaurant?.address}</i> 
        {state !== 'confirm' ? 
          <Badge value={text} className="p-ml-2" severity={type}></Badge>
          : ''
        }
        
      </span>
    )
  }
  

  const statuses = [
    {code: 'new', name: 'Свободно'},
    {code: 'start', name: 'Идёт набор'},
    {code: 'active', name: 'В процессе выполнения'},
    {code: 'verification', name: 'На проверке'},
    {code: 'verified', name: 'Проверено'},
    {code: 'not_verified', name: 'Повторно сдать задание'},
    {code: 'payout', name: 'Ожидает выплаты'},
    {code: 'reject', name: 'Отменена'},
    {code: 'confirm', name: 'Завершена'}
  ];

  const onGlobalSearchChange = (e: any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    filters['id'].value = value;

    setFilters(_filters1);
    setSearch(value)
  }

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    filters['state'].value = value?.code;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  }

  const renderHeader = () => {
    return (
      <div className="p-d-flex p-jc-between p-flex-wrap">
        <Button label="Обновить" onClick={handleRefresh} className="button-primary-out p-m-1"/>
        <InputText 
          value={search} 
          placeholder="Поиск по ИД"
          onChange={onGlobalSearchChange} 
          className="p-m-1"
        />
        <Dropdown value={globalFilterValue} options={statuses} 
          onChange={onGlobalFilterChange} 
          className="p-column-filter p-m-1" optionLabel="name" showClear
          placeholder="Выберите статус" />
      </div>
    )
  }
  const header = renderHeader();

  const rowExpansionTemplate = (data: any) => {
    const state = (rowData: any) => {
      const _filter = data.shopper.filter( (i: any) => i.accountId === rowData.accountId );
      return _filter.length ? toStatusRaffleTemplate(_filter[0].state) : toStatusRaffleTemplate(rowData.state)
    }

    return (
        <div className="orders-subtable" style={{width: '100%'}}>
          <h5>Список участников розыгрыша - "
            <span className="p-text-normal">{data.name}</span>
          "</h5>
            <DataTable value={data.shopper?.length ? data.shopper : data.raffles} responsiveLayout="scroll" className="table-mobile-rows" >
                <Column field="name" header="Участник"
                  body={(rowData) => nameAccountTemplate(rowData?.account)}
                  sortable sortField="name"
                  style={{ maxWidth: '100%' }}
                ></Column>
                <Column field="account.phone" header="Телефон"
                  sortable sortField="account.phone"
                  style={{ maxWidth: '8rem' }}
                ></Column>
                <Column 
                  field="state" 
                  header="Статус" 
                  body={(rowData)=>state(rowData)}
                  sortable style={{ maxWidth: '8rem' }}
                ></Column>
            </DataTable>
        </div>
    );
}

  return (
    <div className="content">

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="card">
          <DataTable value={tasks} 
            scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
            responsiveLayout="stack" breakpoint="768px" loading={loading}
            className="table-mobile-rows" stripedRows filters={filters}
            rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
            // paginator rows={10}
            header={header} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
          >
            <Column expander style={{ maxWidth: '3rem' }} />
            <Column field="name" header="Заведение - адрес"
              body={(rowData) => 
                nameTemplate(
                  rowData?.restaurant, 
                  rowData.raffles.length,
                  rowData.counter,
                  rowData.state
                )
              }
              sortable sortField="name"
              style={{ maxWidth: 'calc(100% - 3rem)' }}
            ></Column>
            <Column 
              field="state" header="Статус"
              body={(rowData)=>toStatusTemplate(rowData.state)}
              sortable sortField="state"
              filterMatchMode="contains"
              style={{ maxWidth: '8rem' }}
            ></Column>
            <Column 
              field="updatedAt" header="Обновление"
              body={(rowData)=>toDateNoTime(rowData.updatedAt)}
              sortable sortField="updatedAt"
              style={{ maxWidth: '8rem' }}
            ></Column>
            <Column body={action} style={{ maxWidth: '4rem' }}></Column>
          </DataTable>
        </div>
      </ScrollPanel>
      
      <TaskWindow currentId={currentId} />
      <ReceiptWindow currentId={currentId} handleClose={closePaymentModeration}/>
      <ModerationWindow taskId={currentId} handleClose={closeViewModeration}/>
      <DeactivateWin show={deactivate} showWindow={setDeactivateWindow} item={item} onAccept={handleDeactivate} />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  tasks: state.secretShopper.tasks.data,
  loading: state.secretShopper.tasks.request.loading
})

export default connect(
  mapStateToProps, 
  { 
    getSecretShopperTasks, 
    showWindowTask,
    showWindowModeration, 
    continueTask,
    deactivateTask
  } 
)(Tasks);