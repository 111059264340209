import React from 'react';
import { Divider } from 'primereact/divider';

import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { IPhone } from 'domain/types/restaurants.type';

interface IProps {
  phones: IPhone[];
  setPhone: (e: any) => void;
}

const AddingPhones: React.FC<IProps> = (props: any) => {
  const {phones, setPhone} = props;

  const scheme = { phone: "", comment: "" } 
  const setPhoneValue = (e: any) => {
    const index = e.target.name;
    setPhone( (prev: IPhone[]) => {
      prev[index].phone = e.target.value;
      return prev;
    } )
  }
  const setPhoneComment = (e: any) => {
    const index = e.target.name;
    setPhone( (prev: IPhone[]) => {
      prev[index].comment = e.target.value;
      return prev;
    } )
  }

  const handleAdd = (e: any) => {
    e.preventDefault();

    setPhone( (prev: any) => {
      return [...prev, scheme]
    })
  }
  const handleRemove = (e: any, phone: IPhone) => {
    e.preventDefault();

    setPhone( (prev: any) => {
      const phones = prev.slice();
      const index = phones.indexOf(phone);
      if(index !== -1) phones.splice(index, 1);

      return phones;
    })
  }

  const renderItem = phones.map( (phone: IPhone, index: number) => (
    <div key={index} className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-md-6">
        <div className="custom-input-text" >
          <label>Номер телефона: </label>
          <InputMask value={phone.phone} name={`${index}`} onChange={setPhoneValue} mask="+79999999999" />
        </div>
      </div>
      <div className="p-col-12 p-md-6 p-d-flex p-ai-center p-jc-between">
        <div className="p-col-10 p-md-11 p-pt-0 p-pb-0 p-pl-0 p-pr-2">
          <Field name="phone.comment">
            {props => (
              <div className="custom-input-text" >
                <label>Комментарий: </label>
                <InputText name={`${index}`} value={phone.comment} onChange={(e) => {
                    props.input.onChange(e)
                    setPhoneComment(e)
                  }}
                />
              </div>
            )}
          </Field>
          
        </div>
        <Button 
          onClick={(e: any) => handleRemove(e, phone)} 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-danger p-col-2 p-md-1"
        />
      </div>
    </div>
  ))

  return (
    <div className="p-col-12 p-p-0">
      <Divider align="left" type="dashed">
        <b>Дополнительные номера</b>
      </Divider>

      <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-ml-2"/>

      {renderItem}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories
})

export default connect(mapStateToProps)(AddingPhones);