import * as constants from "domain/constants/users.constant";
import * as types from "domain/types/users.type";
import { orderBy } from "lodash";

export function getClients(){
  return {
    type: constants.USER_CLIENT_SAGA_GET,
  }
}

export function getUserById(id: string){
  return {
    type: constants.USER_SAGA_GET_BY_ID,
    payload: { clientId: id }
  }
}

export function getManager(id: string){
  return {
    type: constants.USER_SAGA_GET_MANAGER_BY_ID,
    payload: { clientId: id }
  }
}

export function getOwners(){
  return {
    type: constants.USER_OWNER_SAGA_GET,
  }
}

export function registration(values: any) {
  return {
    type: constants.USER_SAGA_REGISTRATION,
    payload: { values }
  }
}

export function mergeOwner(values: any) {
  return {
    type: constants.USER_SAGA_OWNER_AND_MANAGER,
    payload: { values }
  }
}

export function bindManger(values: any) {
  return {
    type: constants.USER_SAGA_BIND_MANAGER_RESTAURANT,
    payload: { values }
  }
}

export function updateBindManger(values: any) {
  return {
    type: constants.USER_SAGA_UPDATE_BIND_MANAGER_RESTAURANT,
    payload: { values }
  }
}

export function setClients(clients: Array<types.IUser>): types.ClientsReduceTypes{
  const _orders = orderBy(clients, ['createdAt'], ['desc'])
  return {
    type: constants.USER_CLIENT_REDUCE_SET,
    payload: { clients: _orders }
  }
}

export function setCurrentUser(user: types.IUser): types.ClientsReduceTypes{
  return {
    type: constants.USER_REDUCE_SET_CURRENT,
    payload: { user }
  }
}

export function setOwners(owners: Array<types.IUser>): types.OwnersReduceTypes{
  return {
    type: constants.USER_OWNER_REDUCE_SET,
    payload: { owners }
  }
}

export function showWindow(window: string, isShow: boolean ): types.ClientsReduceTypes{
  return {
    type: constants.USERS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}


export function reqUsers(isLoad: boolean): types.OwnersReduceTypes{
  return {
    type: constants.USERS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}