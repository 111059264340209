import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Field } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField, TextAreaField, TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";

interface IProps{
  setFile: (value: any) => void;
  file: any;
  currentImg?: string;
}

export const HookahFormWin: React.FC<IProps> = (props: any) => {
  const {setFile, file} = props;
  let refGallery = useRef<any>(null);
  const { categories, currentImg } = props;

  useEffect(()=>{
    return () => setFile(0)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-md-6 p-p-0">
        <div className="p-col-12 p-d-flex p-jc-center">
          <SingleUploadImage 
            ref={refGallery} 
            label={"Загрузить фотографию"} 
            onSelectFIle={setFile} 
            file={file}
            currentImg={currentImg ? currentImg : null}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="name" label="Наименование:" render={TextField}
          />
        </div>

      </div>

      <div className="p-col-12 p-md-6 p-p-0">

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="price" label="Цена:" render={TextField}
            settings={{ placeholder: "Цена за кальян", keyfilter: "int" }}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="categoryId" label="Категория:" render={SelectField}
            settings={{
              options: categories,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите категорию"
            }}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="description" label="Описание:" render={TextAreaField}
          />
        </div>

      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.hookah.categories,
})

export default connect(mapStateToProps)(HookahFormWin);