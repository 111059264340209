export function toRoleUser(value: string){
  switch (value) {
    case 'manager':
      return 'Менеджер';
    case 'owner':
      return 'Владелец'
    case 'client':
      return 'Клиент';
    default:
      return 'не указано';
  }
}