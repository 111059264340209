import * as constants from "domain/constants/norr.constant";
import * as types from "domain/types/norr.type";

const initialState = {
  severity: 'success',
  show: false,
  message: ''
}

export function norr(state = initialState, action: types.NorrTypes): types.INorr {
  switch (action.type) {
    case constants.NORR_SHOW_SUCCESS:
      return {
        severity: 'success',
        show: true,
        message: action.payload.message
      };
    case constants.NORR_SHOW_INFO:
      return {
        severity: 'info',
        show: true,
        message: action.payload.message
      };
    case constants.NORR_SHOW_WARN:
      return {
        severity: 'warn',
        show: true,
        message: action.payload.message
      };
    case constants.NORR_SHOW_ERROR:
      return {
        severity: 'error',
        show: true,
        message: action.payload.message
      };
    case constants.NORR_SHOW_CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default norr;