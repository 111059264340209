import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSecretShopperTaskById, showWindowTask  } from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';

interface IProps{
  currentId: string | null;
}

const TaskDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, showWindowTask, getSecretShopperTaskById, current } = props;
  
  useEffect(() => {
    if(currentId) getSecretShopperTaskById(currentId)
  }, [getSecretShopperTaskById, currentId])

  const handleClose = () => showWindowTask('views', false);

  return (
    <Dialog header="Просмотр задания" visible={visible} style={{ width: '50vw' }} onHide={handleClose}>
      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Заявка:</span>
        <span>{current?.bidId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Заведение:</span>
        <span>{current?.restaurant}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Город:</span>
        <span>{current?.city}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Район:</span>
        <span>{current?.districtId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Выполнение задачи:</span>
        <span>{current?.status}</span>
      </div>

      {current?.receipt && 
        <div className="p-m-1 p-p-2" >
          <p><b>Чек оплаты</b></p>
          <div style={{border: '1px solid black'}} className="p-p-2">
            <img src={`/api/admin/files/receipts/${current?.receipt}`} alt="" width="325px"
              onError={(e: any) => e.target.src='/api/admin/files/default'} />
          </div>
        </div>
      }
      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.secretShopper.tasks.current,
  visible: state.secretShopper.tasks.windows.views
})
export default connect(mapStateToProps, { getSecretShopperTaskById, showWindowTask } )(TaskDialog);