import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Field, Form } from 'react-final-form';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { TextAreaField } from 'components/form/fields';
import { Button } from 'primereact/button';

interface IProps {
  show: boolean;
  showWindow: (isShow: boolean) => void;
  item: any;
  onAccept: (message: string) => void;
}

export const DeactivateWin: React.FC<IProps> = (props: any) => {
  const { show, showWindow, item, onAccept } = props;

  const onSubmit = (values: {message: string}) => {
    onAccept(`Задание "${item?.name}" отменяется! ${values.message}` )
  }

  return (
    <Dialog 
      header="Завершение задания" 
      visible={show} 
      className="p-col-12 p-p-0 p-md-10 p-xl-8" 
      onHide={() => showWindow(false)}
      style={{maxWidth: '50%'}}
    >
      <div className="p-col-12 p-mt-2" style={{boxShadow: "0px 0px 11px -6px red;"}}>
        <h3 style={{margin: '0px', color: 'red'}}>Предупреждение!</h3>
        <div className="p-col-12 p-d-flex p-p-1" style={{color: 'red'}}>
          <div className='p-d-flex p-flex-column p-jc-center'>
            <i className="pi pi-exclamation-triangle p-mr-3" style={{'fontSize': '2em'}}></i>
          </div>
          <p>
            Вы действительно хотите деактивировать задание <strong>"{item?.name}"</strong>?
              В последствии Задание <u>нельзя</u> будет вернуть в Активное.
          </p>
        </div>
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={null}
        render={({ handleSubmit, submitting }) => (
          
            <form onSubmit={handleSubmit} >
              
              <div className="p-col-12">
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="message" 
                  label="Причина:" 
                  render={TextAreaField}
                  settings={{
                    placeholder: "Напишите текст сообщения",
                  }}
                />
              </div>
            
              <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2">
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Завершить задание" className="p-col-12 p-button-raised p-button-danger "/>
                </div>
              </div>
            </form>
        )}
      />
    </Dialog>
  )
}

export default DeactivateWin