import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from "primereact/dialog";
import { getShopperCategories, showWindowQuestion } from "domain/actions/secretShopper.action";
import { categoriesQuestion } from "./question.window";

interface IProps {
  category: any[]
  selectSubcategories: any[]
  setFilters: () => void
  setCategories: (categories: any[]) => void
  setSubcategories: (categories: any[]) => void
}

export const FiltersQuestionWindow: React.FC<IProps> = (props: any) => {
  const { 
    visible, 
    showWindowQuestion,
    subcategories, 
    setFilters,
    category,
    setCategories,
    selectSubcategories,
    setSubcategories, 
    getShopperCategories, 
  } = props;
  
  useEffect(() => { return getShopperCategories() }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => showWindowQuestion('filters', false);
  const handleApply = () => {
    setFilters()
  };

  return (
    <Dialog 
      header="Фильтры по вопросам" 
      visible={visible} 
      className="p-col-12 p-p-0 p-md-10 p-lg-8"
      onHide={handleClose}
      maximizable={true}
    >
      <div className="p-col-12 p-p-0">
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-2 p-mb-3">
          <div className="p-col-12 p-md-6">
            <div className="custom-input-text" >
              <label>Категория заведений: </label>
              <MultiSelect 
                value={selectSubcategories} 
                options={subcategories} 
                onChange={(e) => setSubcategories(e.value)} 
                optionLabel="name"
                placeholder="Выберите категорию"
                showClear
                filter
                filterPlaceholder="Поиск по наименованию"
                maxSelectedLabels={2}
                className="p-col-12 p-p-1 custom-multiselect"
                resetFilterOnHide
                emptyFilterMessage="Нет данных"
                selectedItemsLabel={`${selectSubcategories?.length} выбрано элементов`}
              />
            </div>
          </div>

          <div className="p-col-12 p-md-6">
            <div className="custom-input-text" >
              <label>Категории вопросов: </label>
              <MultiSelect 
                value={category} 
                options={categoriesQuestion} 
                onChange={(e) => setCategories(e.value)} 
                optionLabel="name"
                placeholder="Выберите категорию"
                showClear
                filter
                filterPlaceholder="Поиск по наименованию"
                maxSelectedLabels={2}
                className="p-col-12 p-p-1 custom-multiselect"
                resetFilterOnHide
                emptyFilterMessage="Нет данных"
                selectedItemsLabel={`${category?.length} выбрано элементов`}
              />
            </div>
          </div>
          
        </div>
        
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2">
          <div className="p-col-12 p-md-6">
            <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
          </div>
          <div className="p-col-12 p-md-6">
            <Button label="Применить" className="p-col-12 p-button-raised p-button-success " onClick={handleApply}/>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.secretShopper.questions.windows.filters,
  subcategories: state.secretShopper.category,
})

export default connect(mapStateToProps, {showWindowQuestion, getShopperCategories })(FiltersQuestionWindow);