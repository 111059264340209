import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/stocks.type';
import * as actions from "domain/actions/stocks.action";
import * as constants from "domain/constants/stocks.constant";
import * as reducers from "domain/reducers/stocks.reduce";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { ICity } from 'domain/types/city.type';

const api = new ApiController();
const norr = new NorrController();

interface IFilters {
  cities: ICity[];
  state: string;
};

export async function getStocksFetch( filters: IFilters ): Promise<Result<types.IStock[]>> {
  console.log(filters)
  return await api.get(`/restaurants_stocks`)
}

export async function getStockByIdFetch(id: string): Promise<Result<types.IStock>> {
  return await api.get(`/restaurants_stocks/${id}`)
}

export async function postStockFetch(data: types.IPostStocks): Promise<Result<any>> {
  return await api.post(`/restaurants_stocks`, data)
}

export async function putStockFetch(stockId: string, data: types.IPutStocks): Promise<Result<any>> {
  return await api.put(`/restaurants_stocks/${stockId}`, data)
}

export async function uploadFileFetch(file: File): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/restaurants_stocks/upload/file`, fd)
}

export async function deleteStockFetch(stockId: string): Promise<Result<any>> {
  return await api.delete(`/restaurants_stocks/${stockId}`)
}

export function* getStocks(): any{
  yield put(actions.reqStocks(true))

  const filterReduce = yield select(reducers.getFiltersStocks);

  const filters = {
    cities: filterReduce.cities.map( (city: ICity) => city.id ),
    state: filterReduce.state,
    like: ""
  }

  const response = yield call(getStocksFetch, filters);
  yield call(norr.processing, response, function *(){
    yield put(actions.setStocks(response.value))
  })

  yield put(actions.reqStocks(false))
}

export function* getStockById(action: any): any{
  yield put(actions.reqStocks(true))

  const response = yield call(getStockByIdFetch, action.payload.stockId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrent(response.value))
  })
  

  yield put(actions.reqStocks(false))
}

export function* deleteStock(action: any): any{
  const stockId = action.payload.stock.id;

  const response = yield call(deleteStockFetch, stockId);
  yield call(norr.processing, response, function *(){
    yield call(getStocks)
  }, "Акция удалена")
}

export function* showCurrentWin(action: any): any{
  const stockId = action.payload.stockId;
  const window = action.payload.typeWindow

  const response = yield call(getStockByIdFetch, stockId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrent(response.value))
    yield put(actions.showWindow(window, true))
  })
}

export function* postStock(action: any): any{
  let data = action.payload.stock;
  
  const photo = data.urlPhoto;

  let urlPhoto = "";
  const uploadLogo = yield call(uploadFileFetch, photo)
  const resultLogo = yield call(norr.processing, uploadLogo, function(){
    urlPhoto = uploadLogo.value
  })

  if(!resultLogo) return ;

  data.urlPhoto = urlPhoto;

  const response = yield call(postStockFetch, data)
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('add', false))
    yield call(getStocks)
  },  "Акция создана")
  
}

export function* putStock(action: any): any{
  const data = action.payload.data;
  const photo = data.photo;

  if(photo){
    let urlPhoto = "";
    const uploadLogo = yield call(uploadFileFetch, photo)
    const resultLogo = yield call(norr.processing, uploadLogo, function(){
      urlPhoto = uploadLogo.value
    })
    
    if(!resultLogo) return ;

    data.urlPhoto = urlPhoto;
  }

  const response = yield call(putStockFetch, data.restaurantId, data)
 yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('update', false))
    yield call(getStocks)
  },  "Акция изменена")

}

export function* watch() {
  yield takeEvery(constants.STOCKS_SAGA_GET, getStocks)
  yield takeEvery(constants.STOCKS_SAGA_GET_BY_ID, getStockById)
  yield takeEvery(constants.STOCKS_SAGA_CURRENT_IS_SHOW_WINDOW, showCurrentWin)
  yield takeEvery(constants.STOCKS_SAGA_POST, postStock)
  yield takeEvery(constants.STOCKS_SAGA_PUT, putStock)
  yield takeEvery(constants.STOCKS_SAGA_DELETE, deleteStock)
}

export default function* sagas() {
  yield fork(watch)
}
