export const SETTINGS_SAGA_GET_REVIEWS = "SETTINGS_SAGA_GET_REVIEWS";
export const SETTINGS_SAGA_PUT_REVIEW_PUBLISH = "SETTINGS_SAGA_PUT_REVIEW_PUBLISH";
export const SETTINGS_SAGA_POST_REVIEW_REJECT = "SETTINGS_SAGA_POST_REVIEW_REJECT";
export const SETTINGS_SAGA_PUT_REVIEW_REJECT = "SETTINGS_SAGA_PUT_REVIEW_REJECT";
export const SETTINGS_SAGA_DELETE_REVIEW = "SETTINGS_SAGA_DELETE_REVIEW";

export const SETTINGS_REDUCE_SET_REVIEWS = "SETTINGS_REDUCE_SET_REVIEWS";

export const SETTINGS_CHECKOUT_REQUEST = "SETTINGS_CHECKOUT_REQUEST";
export const SETTINGS_IS_SHOW = "SETTINGS_IS_SHOW";
export const SETTINGS_SHOW_ERROR = "SETTINGS_SHOW_ERROR";