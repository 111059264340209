import * as constants from "domain/constants/settings.constant";
import * as types from "domain/types/settings.type";

// SAGA

export function getReviews(){
  return {
    type: constants.SETTINGS_SAGA_GET_REVIEWS,
  }
}

export function publishReview(id: string, isPublished: boolean) {
  return {
    type: constants.SETTINGS_SAGA_PUT_REVIEW_PUBLISH,
    payload: { id, isPublished }
  }
}

export function rejectReview(reviewId: string, comment: string) {
  return {
    type: constants.SETTINGS_SAGA_POST_REVIEW_REJECT,
    payload: { reviewId, comment }
  }
}

export function updateRejectReview(rejectId: string, values: types.IReviewReject) {
  return {
    type: constants.SETTINGS_SAGA_PUT_REVIEW_REJECT,
    payload: { rejectId, data: values }
  }
}

export function deleteReview(id: string) {
  return {
    type: constants.SETTINGS_SAGA_DELETE_REVIEW,
    payload: { id }
  }
}

// Reduce

export function setReviews(reviews: types.IReview[]): types.SettingsReduceTypes{
  return {
    type: constants.SETTINGS_REDUCE_SET_REVIEWS,
    payload: { reviews }
  }
}

export function showWindow(window: string, isShow: boolean ): types.SettingsReduceTypes{
  return {
    type: constants.SETTINGS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqSettings(isLoad: boolean): types.SettingsReduceTypes{
  return {
    type: constants.SETTINGS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}