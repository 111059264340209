import { combineReducers } from 'redux'
import * as constants from "domain/constants/billing.constant";
import * as types from "domain/types/billing.type";
import { IStatusResponse } from 'domain/types/static.type';

const initialState = {
  orders:[],
  current: null,
  qr: null,
  windows: { 
    new: false,
    view: false,
    send_link: false,
  },
  request: {
    loading: false,
    error: null
  }
}


export function orders(state = initialState.orders, action: types.BillingReduceTypes): any {

  switch (action.type) {
    case constants.BILLING_REDUCE_SET_ORDERS:
      return action.payload;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.BillingReduceTypes): any {
  switch (action.type) {
    case constants.BILLING_REDUCE_SET_CURRENT_ORDER:
      return action.payload;
    default:
      return state;
  }
}

export function qr(state = initialState.qr, action: types.BillingReduceTypes): any {
  switch (action.type) {
    case constants.BILLING_REDUCE_SET_QR:
      return action.payload;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.BillingReduceTypes): any {
  switch (action.type) {
    case constants.BILLING_REDUCE_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.BillingReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.BILLING_REDUCE_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export function getQr(state: { billing: {qr: string} }) {
  return state.billing.qr;
}

export default combineReducers({
  orders,
  current,
  qr,
  windows,
  request
})