import React, { useEffect, useState } from 'react';
import { ExpansionIcon, ExpansionItem } from "components/expansion";
import { ICategory, IDishes } from 'domain/types/restaurants.type';
import ItemKitchen from '../windows/components/item.kitchen';
import { saveHookah, showWindow } from 'domain/actions/restaurants.action';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { Button } from 'primereact/button';

interface IProps {
  category: ICategory | null;
  items: IDishes[];
  setItem: (dish: IDishes) => void;
}

const CategoryMenu: React.FC<IProps> = (props: any) => {
  const [active, setActive] = useState(false);
  const [ hookah, setHookahs ] = useState<IDishes[]>([])
  const [ disable, setDisable ] = useState(true)
  const {category, items, setItem, showWindow, saveHookah} = props;
  
  const handleEdit = (data:IDishes) => {
    setItem(data)
    showWindow('editHookah', true)
  }

  useEffect(()=>{
    const _items = orderBy(items, ['order'], ['asc']).map( (item: IDishes, index: number) => {
      return {
        ...item,
        order: index
      }
    })
    setHookahs(_items) 
  },[items])

  const setOrder = (id:string, order:number, symbol: string) => {
    setDisable(false)
    const _index = hookah.map((i: IDishes) => i.id).indexOf(id)
    setHookahs( (prev: IDishes[]) => {
      if(symbol === 'down'){
        prev.forEach( (i: IDishes, index: number) => {
          if(index === _index) prev[_index].order = order;
          if(index === _index + 1) prev[index].order = index - 1
        })
      }

      if(symbol === 'up'){
        prev.forEach( (i: IDishes, index: number) => {
          if(index === _index) prev[_index].order = order;
          if(index === _index - 1) prev[index].order = index + 1
        })
      }
      
      
      return orderBy(prev, ['order'], ['asc'])
    })
  }

  
  let data = hookah.map((item: IDishes, index: number) => {
    return (
      <div className="p-col-12 p-p-1" key={item.id}>
        <ItemKitchen item={item} order={index} onClick={() => handleEdit(item)} setOrder={setOrder}/>
      </div>
    );
  });

  const handleSaveOrders = () => {
    console.log(hookah)
    saveHookah(hookah, () => setDisable(true))
  }

  return (
    <div className="p-col-12 p-mt-3" key={category.id}>
      <Button onClick={(e:any) => {
        e.preventDefault();
        handleSaveOrders()
      }} className="p-col-12" label="Сохранить порядок" disabled={disable}/>

      <div className="p-col-12 p-p-0 p-d-flex p-ai-center" onClick={()=> setActive(!active)} style={{cursor: 'pointer'}}>
        <h4 className="dishes-category-name p-mr-2 p-mt-2 p-mb-2">{category.length ? category[0].name : "Прочее ..."}</h4>
        <ExpansionIcon
          active={active}
          setActive={()=>{}}
          className="p-as-end p-pt-2"
        />
      </div>

      <ExpansionItem
        className="p-col-12 p-order-6 p-p-0"
        active={active}
        withLine
      >
        {data}
      </ExpansionItem>
      
    </div>
  );
};

export default connect(null, {showWindow, saveHookah})(CategoryMenu);