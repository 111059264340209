import React, { useEffect, useState } from 'react';

import { Field } from 'react-final-form';
import { requiredValidator } from 'components/form/validators';
import { TextField } from 'components/form/fields';
import { IQuestion } from 'domain/types/secretShopper.type';
import SwitchField from './switch.field';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const DataContent: React.FC<IProps> = (props: any) => {
  const { 
    // disabled = true, 
    questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);
  // console.log(questions)

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'main')
    
    setFilter(filtered);
  }, [questions])
  
  const classField = "p-col-12 p-md-6 p-xl-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={classField} key={question.id}>
        <SwitchField question={question} disabled />
      </div>
    )
  })

  return (
    <>
      <h3 className="p-m-2">Основные данные: </h3>
      
        <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
          <div className={classField}>
            <Field
              validate={requiredValidator}
              name="time"
              label="Дата проверки:"
              render={TextField}
              settings={{
                disabled: true
              }}
            />
          </div>

          {rerender}

        </div>
      
    </>
  );
};


export default DataContent;