import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Button } from "primereact/button";
import { getCities } from "domain/actions/cities.action";

interface IProps {
  setType: (type: string) => void;
}

export const FirstLayout: React.FC<IProps> = (props: any) => {
  const {setType, getCities} = props;

  useEffect(() => { return getCities() }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-col-12 p-p-0">
      <div className="p-col-12">
        <Button label="СБП" className="p-col-12 p-button-outlined p-button-info p-mb-2" onClick={()=>setType('qr')}/>
        <Button label="Расчетный счет" className="p-col-12 p-button-outlined p-button-info" onClick={()=>setType('payment')} />
      </div>
    </div>
  );
}

export default connect(null, {getCities})(FirstLayout);