import React, { useEffect, useState } from 'react';
import { IDishes } from 'domain/types/restaurants.type';
import { showWindow, saveOrders } from 'domain/actions/restaurants.action';
import { connect } from 'react-redux';
import { ScrollPanel } from 'primereact/scrollpanel';
import ItemKitchen from './item.kitchen';
import { orderBy } from 'lodash';
import { Button } from 'primereact/button';

interface IProps {
  items: IDishes[];
  setItem: (dish: IDishes) => void;
}

const DishesMenu: React.FC<IProps> = (props: any) => {
  const { showWindow, setItem, items, saveOrders } = props;
  const [ dishes, setDishes ] = useState<IDishes[]>([])
  const [ disable, setDisable ] = useState(true)

  const handleEdit = (data:IDishes) => {
    setItem(data)
    showWindow('editDishes', true)
  }

  useEffect(()=>{
    const _items = orderBy(items, ['order'], ['asc']).map( (item: IDishes, index: number) => {
      return {
        ...item,
        order: index
      }
    })
// console.log(orderBy(_items, ['order'], ['asc']))
    setDishes(_items)
  },[items])

  const setOrder = (id:string, order:number, symbol: string) => {
    setDisable(false)
    const _index = dishes.map((i: IDishes) => i.id).indexOf(id)
    setDishes( (prev: IDishes[]) => {
      if(symbol === 'down'){
        prev.forEach( (i: IDishes, index: number) => {
          if(index === _index) prev[_index].order = order;
          if(index === _index + 1) prev[index].order = index - 1
        })
      }

      if(symbol === 'up'){
        prev.forEach( (i: IDishes, index: number) => {
          if(index === _index) prev[_index].order = order;
          if(index === _index - 1) prev[index].order = index + 1
        })
      }
      
      
      return orderBy(prev, ['order'], ['asc'])
    })
  }

  const handleSaveOrders = () => {
    saveOrders(dishes, () => setDisable(true))
  }

  let data = dishes.map((item: IDishes, index: number) => {
    
    return (
      <div className="p-col-12 p-p-1" key={item.id}>
        <ItemKitchen item={item} order={index} onClick={() => handleEdit(item)} setOrder={setOrder}/>
      </div>
    );
  });

  return (
    <div className="card-mini p-mt-2">
      <ScrollPanel style={{ width: '100%', minHeight: '194px' }} className="custombar ">
        <>
        <Button onClick={(e:any) => {
          e.preventDefault();
          handleSaveOrders()
        }} className="p-col-12" label="Сохранить порядок" disabled={disable}/>
        <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
          {data}
        </div>
        </>
      </ScrollPanel>
    </div>
  );
};

export default connect(null, {showWindow, saveOrders})(DishesMenu);