import React, { useEffect, useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ActionsColumn from "pages/views/components/table/action.column";
import { toStatusStock } from "pages/views/controllers/toStatus";
import { getStocks, showWindow, deleteStock } from "domain/actions/stocks.action";
import AddStockWin from "./windows/add.stock.window";
import DeleteStockWin from './windows/delete.stock.window';
import { IStock } from "domain/types/stocks.type";
import { toDateNoTime } from "utils/toDate.util";

export const ListStocks: React.FC = (props: any) => {
  const [ current, setCurrent ] = useState<IStock | null>(null)
  const { loading, stocks, getStocks, showWindow, deleteStock } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => getStocks(), [getStocks])

  const handleAdd = () => showWindow('add', true);
  const handleRefresh = () => getStocks();
  const handleFilters = () => console.log('filters', true);
  const handleDelete = (item: IStock) => {
    setCurrent(item)
    showWindow('delete', true)
  }
  const handleViewing = (id: string) => console.log(id, 'update');
  const handleQrCode = (id: string) => console.log(id, 'qr_restaurant');

  let menu = (item: any) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(item.id),
    },
    {
      label: 'QR-CODE', 
      icon: 'pi pi-fw pi-qrcode', 
      command:()=> handleQrCode(item.id),
    },
    {
      label: 'Удалить', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> handleDelete(item) 
    }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  const toStatusTemplate = (rowData: any) => <span className={`rest-status rest-status-${rowData.status}`}>{toStatusStock(rowData.state)}</span>
  const imageBody = (rowData: any) => {

    return (
      <div className="p-d-flex p-ai-center">
        <img src={`/api/admin/files/stocks/${rowData.urlPhoto}`} alt="" className="p-mr-4"
          width={48} height={48}
          style={{ borderRadius: '50%', objectFit: "contain" }} 
          onError={(e: any) => e.target.src='https://3raza.com/images/3_.png'} 
        />
        
        <div className="p-d-flex p-flex-column">
          <span > <strong>{rowData.name}</strong> (<i>tel:{'rowData.telephone'}</i>)</span>
          {/* <span > <strong>{rowData.city}</strong>. <i>{rowData.address}</i></span> */}
          <span > <i>{rowData.description}</i>.</span>
          <span> {toDateNoTime(rowData.from)} - {toDateNoTime(rowData.to)} </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2 style={{margin: '0px'}}>Акции</h2>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 208px)' }}>

        <div className="p-col-12 p-pl-0 p-d-flex p-flex-wrap p-ai-baseline p-jc-between">
          <div className="p-d-flex p-flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text p-ml-1"/>
          </div>

          <Button icon="pi pi-sliders-h" onClick={handleFilters} className="p-button-raised p-button-info p-button-text p-ml-1"/>
        </div>

        <div className="card">
          <DataTable 
            value={stocks} 
            scrollable={!isMobile} 
            scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
            responsiveLayout="stack"
            breakpoint="768px" 
            loading={loading}
            className="table-mobile-rows"
          >

            <Column header="Заведение" body={imageBody} sortable sortField="name"></Column>
            <Column body={toStatusTemplate} header="Статус" sortable sortField="status"
              style={{ maxWidth: '7rem' }}></Column>
            <Column body={action} style={{ maxWidth: '5rem', float: "right" }}></Column>

          </DataTable>
        </div>

      </ScrollPanel>

      <AddStockWin />
      <DeleteStockWin stock={current} handleDelete={deleteStock} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  stocks: state.stocks.stocks,
  loading: state.stocks.request.loading
})

export default connect(mapStateToProps, { 
  getStocks, showWindow, deleteStock
})(ListStocks);