import { ICategory } from './../types/restaurants.type';
import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/secretShopper.type';
import * as actions from "domain/actions/secretShopper.action";
import * as constants from "domain/constants/secretShopper.constant";
import * as reducers from "domain/reducers/secretShopper.reduce";
import { Result } from 'domain/types/static.type';
import { orderBy } from "lodash";
import { NorrController } from 'domain/controllers/Response.controller';

const api = new ApiController();
const norr = new NorrController();

export async function getBidsFetch(): Promise<Result<Array<types.IBid>>> {
  return await api.get(`/secret_shopper/bids`)
}

export async function getBidByIdFetch(id: string): Promise<Result<types.IBid>> {
  return await api.get(`/secret_shopper/bids/${id}`)
}

export async function getRafflesFetch(): Promise<Result<Array<types.IRaffle>>> {
  return await api.get(`/shopper/raffles`)
}

export async function getRaffleByIdFetch(id: string): Promise<Result<types.IRaffle>> {
  return await api.get(`/shopper/raffle/${id}`)
}

export async function getTasksFetch(): Promise<Result<Array<types.ITask>>> {
  return await api.get(`/shopper/tasks`)
}

export async function getTaskByIdFetch(id: string): Promise<Result<types.ITask>> {
  return await api.get(`/shopper/tasks/${id}`)
}

export async function getTasksModerationFetch(): Promise<Result<Array<types.IModeration>>> {
  return await api.get(`/secret_shopper/moderation`)
}

export async function getModerationByIdFetch(id: string): Promise<Result<types.IModeration>> {
  return await api.get(`/secret_shopper/moderation/${id}`)
}

export async function getQuestionnaireByTaskIdFetch(id: string): Promise<Result<types.IModeration>> {
  return await api.get(`/secret_shopper/moderation/task/${id}`)
}

export async function createBidFetch(params: any): Promise<Result<null>> {
  return await api.post(`/secret_shopper/bids`, params)
}

export async function createTasksFetch(params: any): Promise<Result<null>> {
  return await api.post(`/secret_shopper/tasks`, params)
}

export async function continueTasksFetch(taskId: string): Promise<Result<void>>{
  return await api.post(`/secret_shopper/tasks/${taskId}/continue`, {})
}

export async function completeTasksFetch(moderationId: string, sum: string): Promise<Result<void>>{
  return await api.put(`/secret_shopper/moderation/${moderationId}/complete`, { sum })
}

export async function deactivateTaskFetch(taskId: string, message: string): Promise<Result<void>>{
  return await api.put(`/shopper/tasks/${taskId}/deactivate`, {message})
}

export async function setReceiptTaskFetch(taskId: string, remuneration: any): Promise<Result<void>>{
  return await api.put(`/shopper/tasks/${taskId}/receipt`, remuneration )
}

export async function getRequestRemunerationFetch(taskId: string): Promise<Result<void>>{
  return await api.get(`/shopper/tasks/${taskId}/remuneration`)
}

export async function getQuestionsFetch(): Promise<Result<types.IQuestion[]>>{
  return await api.get(`/shopper/questionnaires/questions`)
}

export async function getQuestionByIdFetch(questionId: string): Promise<Result<types.IQuestion | null>>{
  return await api.get(`/shopper/questionnaires/questions/${questionId}`)
}

export async function createQuestionFetch( data: types.IQuestion): Promise<Result<void>>{
  return await api.post(`/shopper/questionnaires/questions`, data)
}

export async function updateQuestionFetch(questionId: string, data: types.IQuestion): Promise<Result<void>>{
  return await api.put(`/shopper/questionnaires/questions/${questionId}`, data)
}

export async function deleteQuestionFetch(questionId: string): Promise<Result<void>>{
  return await api.delete(`/shopper/questionnaires/questions/${questionId}`)
}

export async function getSchemasFetch(): Promise<Result<types.ISchema[]>>{
  return await api.get(`/shopper/questionnaires/schemas`)
}

export async function getSchemaByIdFetch(schemaId: string): Promise<Result<types.ISchema | null>>{
  return await api.get(`/shopper/questionnaires/schemas/${schemaId}`)
}

export async function deleteSchemaByIdFetch(schemaId: string): Promise<Result<void>>{
  return await api.delete(`/shopper/questionnaires/schemas/${schemaId}`)
}

export async function getShopperCategoriesFetch(): Promise<Result<ICategory>>{
  return await api.get(`/shopper/categories`)
}

export async function createSchemaFetch( data: types.ISchema): Promise<Result<void>>{
  return await api.post(`/shopper/questionnaires/schemas`, data)
}

export async function updateSchemaFetch(schemaId: string, data: types.ISchema): Promise<Result<void>>{
  return await api.put(`/shopper/questionnaires/schemas/${schemaId}`, data)
}

export async function verifiedTasksFetch(id: string, values: any): Promise<Result<void>>{
  return await api.put(`/secret_shopper/moderation/${id}/not_verified`, values)
}

export async function postFileReceiptFetch(taskId: string, file: any): Promise<Result<void>> {
    const fd = new FormData();
    fd.append('image', file, file.name)
    return await api.post(`/shopper/tasks/upload/receipt`, fd);
}

export function* createBid(action: any): any{
  const values = action.payload;

  const response = yield call(createBidFetch, values);
  if(response.type === "success"){
    yield put(actions.showWindowBid('createBid', false))
    yield call(getBids)
  }else {
    console.log(response, "error bid")
  }
}

export function* getBids(): any{
  yield put(actions.reqBids(true))

  const response = yield call(getBidsFetch);

  if(response.type === "success"){
    yield put(actions.setSecretShopperBids(response.value))
  }else {
    console.log(response, "error token")
  }

  yield put(actions.reqBids(false))
}

export function* getBidById(action: any): any{
  yield put(actions.reqBids(true))

  const response = yield call(getBidByIdFetch, action.payload.bidId);
  if(response.type === "success"){
    yield put(actions.setCurrentBid(response.value))
  }else {
    console.log(response, "error token")
  }

  yield put(actions.reqBids(false))
}

export function* getRaffles(): any{
  yield put(actions.reqRaffles(true))

  const response = yield call(getRafflesFetch);

  yield call(norr.processing, response, function *(){
    yield put(actions.setSecretShopperRaffles(response.value))
  })

  yield put(actions.reqRaffles(false))
}

export function* getRaffleById(action: any): any{
  yield put(actions.reqRaffles(true))

  const response = yield call(getRaffleByIdFetch, action.payload.raffleId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setSecretShopperRaffles(response.value))
  })

  yield put(actions.reqRaffles(false))
}

export function* getTasks(): any{
  yield put(actions.reqTasks(true))

  const response = yield call(getTasksFetch);

  if(response.type === "success"){
    response.value.forEach( (task: any) => {
      const restaurant = task?.restaurant
      task.name = restaurant 
        ? `${restaurant.name} - ${restaurant?.address}` 
        : 'Noname'
    } )

    yield put(actions.setSecretShopperTasks(orderBy(response.value, ['updatedAt'], ['desc'])))
  }else {
    console.log(response, "error token")
  }

  yield put(actions.reqTasks(false))
}

export function* getTaskById(action: any): any{
  yield put(actions.reqTasks(true))

  const response = yield call(getTaskByIdFetch, action.payload.bidId);
  if(response.type === "success"){
    yield put(actions.setCurrentTask(response.value))
  }else {
    console.log(response, "error token")
  }

  yield put(actions.reqTasks(false))
}

export function* getTasksModeration(): any{
  yield put(actions.reqModeration(true))

  const response = yield call(getTasksModerationFetch);
  if(response.type === "success"){
    yield put(actions.setTasksModeration(response.value))
  }else {
    console.log(response, "error reqModeration")
  }

  yield put(actions.reqModeration(false))
}

export function* getModerationById(action: any): any{
  yield put(actions.reqModeration(true))

  const response = yield call(getModerationByIdFetch, action.payload.moderationId);
  if(response.type === "success"){
    yield put(actions.setModerationCurrent(response.value))
  }else {
    console.log(response, "error reqModeration")
  }

  yield put(actions.reqModeration(false))
}

export function* getQuestionnaireByTaskId(action: any): any{
  yield put(actions.reqModeration(true))

  const response = yield call(getQuestionnaireByTaskIdFetch, action.payload.taskId);
  if(response.type === "success"){
    yield put(actions.setModerationCurrent(response.value))
  }else {
    console.log(response, "error reqModeration")
  }

  yield put(actions.reqModeration(false))
}

export function* createTasks(action: any): any{
  yield put(actions.reqBids(true))

  const response = yield call(createTasksFetch, action.payload);

  if(response.type === "error"){
    console.log("response: ", response )
  }else {
    yield put(actions.showWindowBid('views', false))
    yield put(actions.showWindowBid('createTasks', false))
  }

  yield call(getBids)

  yield put(actions.reqBids(false))
}

export function* continueTasks(action: any): any{
  const taskId = action.payload.taskId;
  const response = yield call(continueTasksFetch, taskId);

  if(response.type === "error") return console.log("response: ", response )

  yield call(getTasks)
}

export function* deactivateTask(action: any): any{
  const taskId = action.payload.taskId;
  const message = action.payload.message;

  const response = yield call(deactivateTaskFetch, taskId, message);

  yield call(norr.processing, response, function *(){
    yield call(getTasks)
  })
}

export function* completeTask(action: any): any{
  yield put(actions.reqTasks(true))

  const moderationId = action.payload.moderationId;
  const sum = action.payload.sum;
  const response = yield call(completeTasksFetch, moderationId, sum);

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindowModeration('views', false))

    yield call(getTasks)
    yield call(getTasksModeration)

  })
  
  yield put(actions.reqTasks(false))

}

export function* setReceiptTask(action: any): any{
  const taskId = action.payload.taskId;
  const remuneration = yield select(reducers.getRemuneration)

  remuneration.receipt = yield select(reducers.getUrlReceipt)

  const response = yield call(setReceiptTaskFetch, taskId, remuneration);

  if(response.type === "success") {
    yield put(actions.showWindowTask('payment', false))
    yield call(getTasks)
  }
  if(response.type === "error") return console.log("response: ", response )
}

export function* notVerifiedTask(action: any): any{
  yield put(actions.reqTasks(true))

  const values = action.payload;
  const response = yield call(verifiedTasksFetch, values.id, values);

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindowModeration('views', false))
    yield call(getTasks)
    yield call(getTasksModeration)
  })

  yield put(actions.reqTasks(false))
}

export function* uploadReceipt(action: any): any{
  yield put(actions.reqTasks(true))

  const {taskId, file} = action.payload;

  const response = yield call(postFileReceiptFetch, taskId, file)

  if(response.type === 'success') {
    yield put(actions.setUrlReceipt(response.value))
  }

  yield put(actions.reqTasks(false))
}

export function* getRequestRemuneration(action: any): any{
  const taskId = action.payload.taskId;

  const response = yield call(getRequestRemunerationFetch, taskId)

  if(response.type === "success") {
    yield put(actions.setRequestRemuneration(response.value))
  }
  if(response.type === "error") return console.log("response: ", response )
}

export function* getQuestions(): any{
  const response = yield call(getQuestionsFetch)

  yield call(norr.processing, response, function *(){
    const _orders = orderBy(response.value, ['createdAt'], ['desc'])
    yield put(actions.setQuestions(_orders))
  })
}

export function* createQuestion(action: any): any{
  const data = action.payload.data;

  const response = yield call(createQuestionFetch, data)

  yield call(norr.processing, response, function *(){
    yield call(getQuestions)
    yield put(actions.showWindowQuestion("views", false))
  })
}

export function* deleteQuestion(action: any): any{
  const id = action.payload.questionId;
  const cb = action.payload.callback;
  const response = yield call(deleteQuestionFetch, id)

  yield call(norr.processing, response, function *(){
    yield call(getQuestions)
    cb()
  })
}

export function* updateQuestion(action: any): any{
  const data = action.payload.data;
  const response = yield call(updateQuestionFetch, data.id, data)

  yield call(norr.processing, response, function *(){
    yield call(getQuestions)
    yield put(actions.showWindowQuestion("views", false))
    yield put(actions.clearQuestion())
  })
}

export function* showQuestion(action: any): any{
  
  const questionId = action.payload.questionId;
  const response = yield call(getQuestionByIdFetch, questionId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestion(response.value))
    yield put(actions.showWindowQuestion("views", true))
  })
}

export function* getSchemas(): any{
  const response = yield call(getSchemasFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setSchemas(response.value))
  })
}

export function* createSchema(action: any): any{
  yield put(actions.reqQuestionnaire(true))

  const data = action.payload.data;
  const response = yield call(createSchemaFetch, data)

  yield call(norr.processing, response, function *(){
    yield call(getSchemas)
    yield put(actions.showWindowSchema("views", false))
  })

  yield put(actions.reqQuestionnaire(false))
}

export function* updateSchema(action: any): any{
  yield put(actions.reqQuestionnaire(true))

  const data = action.payload.data;
  const response = yield call(updateSchemaFetch, data.id, data)

  yield call(norr.processing, response, function *(){
    yield call(getSchemas)
    yield put(actions.showWindowSchema("views", false))
    yield put(actions.clearSchema())
  })

  yield put(actions.reqQuestionnaire(false))
}

export function* showSchema(action: any): any{
  
  const schemaId = action.payload.schemaId;
  const response = yield call(getSchemaByIdFetch, schemaId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setSchema(response.value))
    yield put(actions.showWindowSchema("views", true))
  })
}

export function* delSchema(action: any): any{
  const schemaId = action.payload.schemaId;

  const response = yield call(deleteSchemaByIdFetch, schemaId)
  yield call(norr.processing, response, function *(){
    yield call(getSchemas)
  })
}

export function* getShopperCategories(): any{
  const response = yield call(getShopperCategoriesFetch)
  yield call(norr.processing, response, function *(){
    yield put(actions.setShopperCategories(response.value))
  })
}

export function* watchSecretShopper() {
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CREATE_BID, createBid)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_BIDS, getBids)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_BID_BY_ID, getBidById)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_RAFFLES, getRaffles)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_RAFFLE_BY_ID, getRaffleById)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASKS, getTasks)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASK_BY_ID, getTaskById)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CREATE_TASKS, createTasks)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_TASK_MODERATION, getTasksModeration)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_MODERATION_BY_ID, getModerationById)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_QUESTIONNAIRE_BY_TASK_ID, getQuestionnaireByTaskId)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CONTINUE_TASK, continueTasks)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_COMPLETE_TASK, completeTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_DEACTIVATE_TASK, deactivateTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_SET_RECEIPT, setReceiptTask)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_VERIFIED_TASK, notVerifiedTask)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT, uploadReceipt)

  yield takeEvery(constants.SECRET_SHOPPER_GET_REMUNERATION_BY_TASK, getRequestRemuneration)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_QUESTIONS, getQuestions)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CREATE_QUESTIONS, createQuestion)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_UPDATE_QUESTIONS, updateQuestion)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_DELETE_QUESTIONS, deleteQuestion)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_QUESTIONS_IS_SHOW, showQuestion)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_SCHEMAS, getSchemas)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_CREATE_SCHEMA, createSchema)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_UPDATE_SCHEMA, updateSchema)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_SCHEMA_IS_SHOW, showSchema)
  yield takeEvery(constants.SECRET_SHOPPER_SAGA_SCHEMA_DELETE, delSchema)

  yield takeEvery(constants.SECRET_SHOPPER_SAGA_GET_CATEGORIES, getShopperCategories)
}

export default function* secretShopperSagas() {
  yield fork(watchSecretShopper)
}