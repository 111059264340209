import React, { useEffect, useState } from 'react';

import { Field } from 'react-final-form';
import {  
  composeValidators, 
  minLengthValidator, 
  requiredValidator 
} from 'components/form/validators';
import { TextAreaField } from 'components/form/fields';
import { IQuestion } from 'domain/types/secretShopper.type';
import SwitchField from './switch.field';

interface IProps {
  disabled?: boolean;
  questions: IQuestion[];
}

export const ImpressionContent: React.FC<IProps> = (props: any) => {
  const { disabled = false, questions } = props;
  const [ filters, setFilter ] = useState<IQuestion[]>([]);

  useEffect(() => {
    const filtered = questions.filter((question: IQuestion) => question.category === 'impression')
    
    setFilter(filtered);
  }, [questions])

  const classField = "p-col-12 p-md-6 p-xl-4";

  const rerender = filters.map((question: IQuestion) =>{
    return (
      <div className={question.type === 'textArea' ? 'p-col-12' : classField} key={question.id}>
        <SwitchField question={question} disabled={disabled} />
      </div>
    )
  })

  return (
    <>
      <h3 className="p-m-2">Впечатление о посещении: </h3>

      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">

        {rerender}

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator, minLengthValidator(64))}
            name="review"
            label="Отзыв"
            render={TextAreaField}
            settings={{
              disabled: disabled
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ImpressionContent;