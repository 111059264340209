import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { ScrollPanel } from "primereact/scrollpanel";


export const MainSettingsPage: React.FC = () => {
  return (
    <>
      <span className="content-header">Главные настройки</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>


      </ScrollPanel>
    </>
  );
}

export default MainSettingsPage;