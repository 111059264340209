import { put, fork, takeEvery, call } from 'redux-saga/effects'
import * as norr from 'domain/actions/norr.action';
import * as constants from 'domain/constants/norr.constant';

const wait = async () => {
  await setTimeout(function() {}, 300)
  return;
}

export function* showError(action: any): any{
  yield put(norr.error(action.payload.msg))
  yield call(wait)
  yield put(norr.clear())
}

export function* watch() {
  yield takeEvery(constants.NORR_SAGA_SHOW_ERROR, showError)
}

export default function* sagas() {
  yield fork(watch)
}