import * as validators from 'components/form/validators';
import { ICategory, IPhone, IPostRestaurant, IPutRestaurant } from "domain/types/restaurants.type";

export function updateRestaurantFormat(data: any, gallery: Array<File>, file: any, phones: Array<IPhone>): IPutRestaurant {
  const address = `${data.newCity.value.replace(`${data.newCity?.data.city_with_type}, `, '')}`;
  const city = {
    name: data.newCity?.data.city,
    region: data.newCity?.data.region_kladr_id
  }

  const hashtags = [];

  if(data.hashtags){
    for(let key in data.hashtags){
      if(data.hashtags[key]) hashtags.push(key)
    }
  }

  return {
    main: {
      name: data.name ? data.name : "",
      telephone: data.telephone ? data.telephone : "",
      address: address,
      description: data.description ? data.description : "",
      comment: data.comment ? data.comment : "",
      city: city,
      logo: data.logo ? data.logo : "",
    },
    addInfo:{
      phoneOwner: data?.addInfo?.phoneOwner ? data.addInfo.phoneOwner : "",
      addressEntry: data?.addInfo?.addressEntry ? data.addInfo.addressEntry : "",
      addPhone:"[]",
      phoneComment: data?.addInfo?.phoneComment ? data.addInfo.phoneComment : "",
      metro: data?.addInfo?.metro ? data.addInfo.metro : "",
      averageReceipt: data?.addInfo?.averageReceipt ? data.addInfo.averageReceipt : "",
    },
    gallery: data.gallery,
    logotype: file,
    newGallery: gallery ? gallery : [],
    social: {
      facebook: data?.social?.facebook ? data?.social?.facebook : "",
      instagram: data?.social?.instagram ? data?.social?.instagram : "",
      telegram: data?.social?.telegram ? data?.social?.telegram : "",
      twitter: data?.social?.twitter ? data?.social?.twitter : "",
      vk: data?.social?.vk ? data?.social?.vk : "",
      youtube: data?.social?.youtube ? data?.social?.youtube : ""
    },
    workingHours: data.workingHours,
    hashtags: hashtags,
    categories: data.categories.map( (category: ICategory) => category.id),
    addPhones: phones
  }
}

export function formationData(data: any): any {
  if(!data) return data;
  const hashtags = {} as any
  data.hashtags.forEach((hashtag:any) => {
    hashtags[hashtag.id] = true;
  })

  return {
    name: data.name,
    city: data.city,
    address: data.address,
    telephone: data.telephone,
    email: "",
    logo: data.logo ? data.logo : null,
    rating: data.rating,
    description: data.description,
    comment: data.comment,
    gallery: data.gallery ? data.gallery : [],
    workingHours: data.workingHours,
    addInfo: data.addInfo,
    social: data.social,
    hashtags: hashtags,
    categories: data.categories,
  }
}

interface IPostPut extends IPostRestaurant {
  newCity: {
    name: string;
    region: string;
  };
}

export function validate(values: IPostPut): string | undefined {
  if (
    validators.requiredValidator(values.name) ||
    // (validators.requiredValidator(values.telephone) && validators.phoneValidator(values.telephone)) || 
    validators.requiredValidator(values.addInfo?.averageReceipt) || 
    // (validators.requiredValidator(values.addInfo?.phoneOwner) && validators.phoneValidator(values.addInfo.phoneOwner)) || 
    (validators.requiredValidator(values.newCity) && validators.fullAddressValidator(values.newCity))
  ) {
    return "Не полностью заполнена основная информация";
  }

  if(!values.categories.length) return "Не выбрана категория заведения";

  if(!!validators.fullAddressValidator(values.newCity)){
    return "Не полностью заполнен адрес заведения";
  }

  if (
    (validators.requiredValidator(values.workingHours.monday) && validators.timeFormatValidator(values.workingHours.monday)) || 
    (validators.requiredValidator(values.workingHours.tuesday) && validators.timeFormatValidator(values.workingHours.tuesday)) ||
    (validators.requiredValidator(values.workingHours.wednesday) && validators.timeFormatValidator(values.workingHours.wednesday)) ||
    (validators.requiredValidator(values.workingHours.thursday) && validators.timeFormatValidator(values.workingHours.thursday)) ||
    (validators.requiredValidator(values.workingHours.friday) && validators.timeFormatValidator(values.workingHours.friday)) ||
    (validators.requiredValidator(values.workingHours.saturday) && validators.timeFormatValidator(values.workingHours.saturday)) ||
    (validators.requiredValidator(values.workingHours.sunday) && validators.timeFormatValidator(values.workingHours.sunday))
  ) {
    return 'Не полностью заполнена информация "часы работы"';
  }

  return undefined;
}
