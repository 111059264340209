import React from "react";
import { connect } from "react-redux";

import Tasks from "./tasks";

export const SecretShopperTasks: React.FC = (props: any) => {

  return (
    <div className="content">
      <span className="content-header">Задания</span>

      <Tasks />
    </div>
  );
}


export default connect(null, null )(SecretShopperTasks);