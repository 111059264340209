import React from "react";
import { connect } from "react-redux";

import { TabPanel, TabView } from "primereact/tabview";
import Orders from "./orders";
import Archive from "./archive";
import OrderWindows from "../windows/order.window";
import CreateOrderWindows from "../windows/create.order.window";

export const ListOrders: React.FC = (props: any) => {

  return (
    <div className="content">

      <TabView>
        <TabPanel header="Счета на оплату">
          <Orders />
        </TabPanel>
        <TabPanel header="Архив">
          <Archive />
        </TabPanel>
      </TabView>

      <OrderWindows />
      <CreateOrderWindows />
    </div>
  );
}


export default connect(null, null )(ListOrders);