import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getKitchens, showWindow, deleteCategory } from 'domain/actions/restaurants.action';
import { ICategory } from "domain/types/restaurants.type";

import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import EditKitchenWin from "./edit.kitchen.window";
import { DeleteWin } from "pages/views/components";

export const NationalityKitchen: React.FC = (props: any) => {
  const { loading, kitchen, getKitchens, showWindow, deleteCategory } = props;
  const [ selected, setSelected ] = useState<ICategory | null>(null);
  const [ show, setShow ] = useState<boolean>(false)

  useEffect(() => getKitchens(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => showWindow('addKitchen', true);
  const handleRefresh = () => getKitchens();
  const handleEdit = (item: ICategory) => {
    showWindow('editKitchen', true)
    setSelected(item)
  };
  const handleDelete = (_item: ICategory) => {
    setSelected( _item )
    setShow(true)
  };
  const onDelete = () => {
    if(selected) deleteCategory(selected.id, () => {
      setSelected(null)
      setShow(false)
    })
  };

  let menu = (item: ICategory) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-check-circle', 
      command:()=> handleEdit(item)
    },
    {label: 'Удалить', icon: 'pi pi-fw pi-trash', command:()=> handleDelete(item) }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-pl-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text p-ml-1"/>
          </div>
        </div>

        <div className="card">
        <DataTable value={kitchen} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column field="name" header="Название"></Column>
          <Column field="description" header="Описание"></Column>
          <Column body={action} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
        </div>

      </ScrollPanel>

      <EditKitchenWin selected={selected} />
      <DeleteWin show={show} showWindow={setShow} item={selected} deleteItem={onDelete} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  kitchen: state.restaurants.kitchen.nationality,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { getKitchens, showWindow, deleteCategory })(NationalityKitchen);