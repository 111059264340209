export const COUPONS_SAGA_GET = "COUPONS_SAGA_GET";
export const COUPONS_SAGA_GET_BY_ID = "COUPONS_SAGA_GET_BY_ID";
export const COUPONS_SAGA_POST = "COUPONS_SAGA_POST";
export const COUPONS_SAGA_PUT = "COUPONS_SAGA_PUT";
export const COUPONS_SAGA_DELETE = "COUPONS_SAGA_DELETE";
export const COUPONS_SAGA_CURRENT_IS_SHOW_WINDOW = "COUPONS_SAGA_CURRENT_IS_SHOW_WINDOW";

export const COUPONS_REDUCE_SET = "COUPONS_REDUCE_SET";
export const COUPONS_REDUCE_SET_CURRENT = "COUPONS_REDUCE_SET_CURRENT";
export const COUPONS_REDUCE_SET_FILTERS = "COUPONS_REDUCE_SET_FILTERS";


export const COUPONS_CHECKOUT_REQUEST = "COUPONS_CHECKOUT_REQUEST";
export const COUPONS_IS_SHOW = "COUPONS_IS_SHOW";
export const COUPONS_SAGA_SHOW_ERROR = "COUPONS_SAGA_SHOW_ERROR";