export const BILLING_SAGA_BUY_COUPONS = "BILLING_SAGA_BUY_COUPONS";
export const BILLING_SAGA_GET_ORDERS = "BILLING_SAGA_GET_ORDERS";
export const BILLING_SAGA_GET_ARCHIVE_ORDERS = "BILLING_SAGA_GET_ARCHIVE_ORDERS";
export const BILLING_SAGA_GET_ORDER = "BILLING_SAGA_GET_ORDER";
export const BILLING_SAGA_ACTIVE_COUPONS = "BILLING_SAGA_ACTIVE_COUPONS";
export const BILLING_SAGA_ARCHIVE_COUPONS = "BILLING_SAGA_ARCHIVE_COUPONS";

export const BILLING_SAGA_CREATE_ORDER = "BILLING_SAGA_CREATE_ORDER";
export const BILLING_SAGA_SEND_ORDER = "BILLING_SAGA_SEND_ORDER";
export const BILLING_SAGA_POST_LINK = "BILLING_SAGA_POST_LINK";
export const BILLING_REDUCE_SET_QR = "BILLING_REDUCE_SET_QR";

export const BILLING_SAGA_CREATE_ORDER_FOR_ACCOUNT = "BILLING_SAGA_CREATE_ORDER_FOR_ACCOUNT";

export const BILLING_REDUCE_SET_ORDERS = "BILLING_REDUCE_SET_ORDERS";
export const BILLING_REDUCE_SET_CURRENT_ORDER = "BILLING_REDUCE_SET_CURRENT_ORDER";
export const BILLING_REDUCE_IS_SHOW = "BILLING_REDUCE_IS_SHOW";
export const BILLING_REDUCE_CHECKOUT_REQUEST = "BILLING_REDUCE_CHECKOUT_REQUEST";