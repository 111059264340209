import React from "react";
import { connect } from "react-redux";

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { showError } from "domain/actions/norr.action";
import { showWindow, publishReview } from "domain/actions/settings.action";
import { IReview } from "domain/types/settings.type";
import { toDateNoTime } from "utils/toDate.util";
import { composeValidators, minLengthValidator, requiredValidator } from "components/form/validators";
import { RatingField, TextAreaField } from "components/form/fields";
import { Button } from "primereact/button";

interface IProps {
  review: IReview | null;
}

export const UpdateReviewWin: React.FC<IProps> = (props: any) => {
  const { visible, review } = props;
  const { showWindow, publishReview } = props;

  const handleClose = () => showWindow('review_update', false);
  const onSubmit = () => {
    publishReview(review.id, !review.publish)
  }

  return (
    <Dialog 
      header={`Отзыв от ${review ? toDateNoTime(review.createdAt) : ''}`} 
      visible={visible} 
      className="p-col-12 p-p-0 p-md-10 p-lg-8 p-xl-6"
      onHide={handleClose}
      maximizable={true}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={review ? review : {}}
        render={({ handleSubmit }) => (
        
          <form onSubmit={handleSubmit}>
            <div className="p-mt-1 p-mb-3">
              <Field
                validate={composeValidators(requiredValidator)}
                name="balls"
                label="Общая оценка:"
                render={RatingField}
                settings={{
                  disabled: true
                }}
              />
            </div>
            <div className="p-mt-1 p-mb-3">
              <Field
                validate={composeValidators(requiredValidator, minLengthValidator(119))}
                name="review"
                label="Текст отзыва:"
                render={TextAreaField}
                settings={{
                  placeholder: "Ваше общее впечатление (минимум 120 символов)",
                  rows:5,
                  disabled: true
                }}
              />
            </div>

            {!review.publish && (
              <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
                <Button 
                  label={`${review.rejects.length ? "Проверить комментарий" : "Не прошел модерацию"}`}
                  className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-danger" 
                  onClick={(e)=>{
                    e.preventDefault();
                    showWindow('review_reject', true)
                }} />
              </div>
            )}
            

            <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
              <Button 
                label="Отмена" 
                className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" 
                onClick={(e)=>{
                  e.preventDefault();
                  handleClose()
              }} />
              <Button 
                label={`${review.publish ? 'Снять с публикации' : 'Опубликовать'}`} 
                className={`p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-${review.publish ? 'warning' : 'success'}`}
                disabled={!!review.rejects.length}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.settings.windows.review_update,
})

export default connect(mapStateToProps, { 
  showWindow, showError, publishReview
})(UpdateReviewWin);