import { combineReducers } from 'redux'
import * as constants from "domain/constants/cities.constant";
import * as types from "domain/types/city.type";
import { IStatusResponse } from 'domain/types/static.type';


const initialState = {
  cities: [],
  request: {
    loading: false,
    error: null
  }
};

export function cities(state = initialState.cities, action: types.CitiesReduceTypes): any {
  switch (action.type) {
    case constants.CITIES_REDUCE_SET_ALL:
      return action.payload.cities;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.CitiesReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.CITIES_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export default combineReducers({
  cities,
  request,
})