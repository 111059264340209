import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  showWindow,
} from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import AddContent from "./add";
import { getHashtags } from "domain/actions/hashtags.action";

export const AddWin: React.FC = (props: any) => {
  const { visible } = props;
  const { showWindow, getHashtags } = props;

  const handleClose = () => showWindow('add', false);

  useEffect(() => getHashtags(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog 
      header="Новое заведение" 
      visible={visible} 
      className="p-col-12 p-p-0 p-md-10 p-lg-8"
      onHide={handleClose}
      maximizable={true}
    >
      <AddContent />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.add
})

export default connect(mapStateToProps, { 
  showWindow, getHashtags
})(AddWin);