import React from 'react';
import RoutesMap from "pages/routes";
import moment from "moment";
import { locale, addLocale } from "primereact/api";
import { dataPickerSetting } from "utils/settings";
import { NorrComponent } from 'pages/views/components';

import 'moment/locale/ru';

import './assets/styles/App.css';
import './assets/styles/index.css';
import './assets/styles/custom.css';
import './assets/styles/windows.css';
import './assets/styles/gallery.css';
import './assets/styles/dishes.css';
import './assets/styles/mobile.css';

import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.min.css';

import 'react-pro-sidebar/dist/css/styles.css';
import 'react-dadata/dist/react-dadata.css';

moment.locale("ru");
addLocale("ru", dataPickerSetting);
locale("ru");

const App:React.FC = () => (
  <>
    <RoutesMap />
    <NorrComponent />
  </>
);

export default App;