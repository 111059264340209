import React from 'react';
import { connect } from "react-redux";

import { Button } from 'primereact/button';
// import { showWindowSchema } from 'domain/actions/secretShopper.action';
import { Divider } from 'primereact/divider';
import { toDate } from 'utils/toDate.util';
import { toStatusOrder } from 'pages/views/controllers/toStatus';
import { showError } from "domain/actions/norr.action";
import { activeCoupon } from "domain/actions/billing.action";

export const OrderView: React.FC<any> = (props: any) => {
  const { order, activeCoupon, showError } = props;

  const handleShowQuestions = () => {
    console.log(order?.coupon?.questionnaireId, order?.coupon.id)
    // getQuestionnairesTemplateById(item.questionnaireId, item.id)
  }
  const handleActiveCoupon = () => {
    if(order?.coupon?.order?.typePayment !== 'pay_acc') {
      showError('Купон можно активировать вручную, только если тип оплату указан как "Расчетный счет"')
    }else {
      activeCoupon(order.coupon.id)
    }
  }


  if(!order) return <></>;

  return (
    <>
      <div className="p-col-12 p-text-center">
        <p className="p-p-0">
          Услуга 
          <i> тайного покупателя </i> 
          
          {order.coupon.state === 'confirm' ? (
            <span style={{color: 'rgb(27 178 38)'}}><u> запущена </u></span>
          ) : ( <span style={{color: 'red'}}><u> не запущена </u></span> )}
          
        </p>
      </div>

      <Divider align="center"> Заведение </Divider>

      <div className="p-col-12 p-p-0 p-mt-2 p-text-center">
        <span><strong>{order.restaurant && order.restaurant.name}</strong></span>
        {order?.restaurant?.deletedAt && 
          <div style={{color: 'red'}}>
            {` (Заведение удалено - от ${toDate(order?.restaurant?.deletedAt)})`}
          </div>
        }
      </div>

      <div className="p-col-12 p-p-0 p-mt-2 p-text-center">
        <span >{order.restaurant && `г.${order.restaurant.city?.name}, ${order.restaurant.address}`}</span>
      </div>

      <Divider align="center"> Купон </Divider>

      <div className="p-col-12 p-p-0 p-mt-2 p-d-flex p-jc-between">
        <span>Кол-во участников: </span>
        <span>{order.coupon.quantityShoppers}</span>
      </div>

      <Button 
        label={order?.coupon.questionnaire ? `Анкета: ${order?.coupon.questionnaire.name}` : `Анкета удалена администрацией`} 
        className="p-col-12 p-mt-2 p-button-outlined" 
        onClick={handleShowQuestions}
        disabled={!order?.coupon.questionnaire}
      ></Button>

      <Divider align="center">  Чек </Divider>

      <div className="p-col-12 p-p-0 p-mt-2 p-d-flex p-jc-between">
        <span>Дата:</span>
        <span>{`${toDate(order.coupon.order.createdAt)}`}</span>
      </div>
      <div className="p-col-12 p-p-0 p-mt-2 p-d-flex p-jc-between">
        <span>Сумма:</span>
        <span>{order.coupon.order.amount}₽</span>
      </div>
      <div className="p-col-12 p-p-0 p-mt-2 p-d-flex p-jc-between">
        <span>Состояние:</span>
        <span 
          className={`task-status task-status-${order.coupon.order.state}`}
        >{toStatusOrder(order.coupon.order.state)}</span>
      </div>
        
      <div className="p-col-12 p-p-0 p-mt-2 p-d-flex p-jc-between">
        <span>Тип платежа:</span>
        {order.coupon.order.typePayment === 'qr' && (
          <span><i> СБП: QR-CODE </i></span>
        )}
        {order.coupon.order.typePayment === 'pay_acc' && (
          <span><i> Расчетный счет </i></span>
        )}
          
      </div>

      <Divider align="center"> Настройки </Divider>

      <Button 
        label='Активировать купон вручную' 
        className="status-tag status-tag--success p-col-12"
        onClick={handleActiveCoupon}
        disabled={order.coupon.state === 'confirm'}
      ></Button>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  order: state.billing.current,
})

export default connect(mapStateToProps, {
  activeCoupon,
  showError
})(OrderView);