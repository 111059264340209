import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Field } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { MultiSelectField, SelectField, TextAreaField, TextField } from "components/form/fields";
import SingleUploadImage from "components/upload/single.upload";

interface IProps{
  setFile: (value: any) => void;
  onClear: () => void;
  file: any;
  currentImg?: string;
}

export const DishesFormWin: React.FC<IProps> = (props: any) => {
  const {setFile, file, onClear, tags} = props;
  let refGallery = useRef<any>(null);

  useEffect(()=>{
    return () => setFile(0)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { categories, kitchens, currentImg } = props;

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <div className="p-col-12 p-md-6 p-p-0">
        <div className="p-col-12">
          <SingleUploadImage 
            ref={refGallery}
            label={"Загрузить фотографию"}
            onSelectFIle={setFile} file={file}
            currentImg={currentImg ? currentImg : null}
            onClear={onClear}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="name" label="Наименование:" render={TextField}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="description" label="Описание:" render={TextAreaField}
          />
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-p-0">

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="price" label="Цена:" render={TextField}
            //eslint-disable-next-line
            settings={{ placeholder: "Цена за блюдо", keyfilter: /[\d\/]+$/ }} 
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="categoryId" label="Категория:" render={SelectField}
            settings={{
              options: categories,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите категорию"
            }}
          />
        </div>

        <div className="p-col-12">
          <Field
            validate={composeValidators(requiredValidator)}
            name="kitchenId" label="Национальность кухни:" render={SelectField}
            settings={{
              options: kitchens,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите категорию"
            }}
          />
        </div>

        <div className="p-col-12">
          <Field
            name="tagIds" label="Теги:" render={MultiSelectField}
            settings={{
              options: tags,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите необходимые теги",
              filterPlaceholder: "Поиск по наименованию",
              emptyFilterMessage: "Нет данных",
              maxSelectedLabels: 2,
              resetFilterOnHide: true,
            }}
          />          
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.kitchen.categories,
  kitchens: state.restaurants.kitchen.nationality,
  tags: state.restaurants.kitchen.tags
})

export default connect(mapStateToProps)(DishesFormWin);