import { combineReducers } from 'redux'
import * as constants from "domain/constants/auth.constant";
import * as types from "domain/types/auth.type";
import { IStatusResponse } from 'domain/types/static.type';
import { load } from 'redux-localstorage-simple';

const savedReduce: any = load();

const initialState = {
  isAuthenticated: (savedReduce && savedReduce.auth) ? savedReduce.auth.isAuthenticated : false,
  administrator: null,
  request: {
    loading: false,
    error: null
  }
};

export function isAuthenticated(state = initialState.isAuthenticated, action: types.AuthReduceTypes): boolean {
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_AUTH:
      return action.payload.isAuthenticated;
    default:
      return state;
  }
}

export function administrator(state = initialState.administrator, action: types.AuthReduceTypes): types.IAdministrator | null {
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_ADMINISTRATOR:
      const { administrator } = action.payload;
      return administrator;
    case constants.AUTH_REDUCE_CLEAR_ADMINISTRATOR:
      return null;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.AuthReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.AUTH_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export default combineReducers({
  isAuthenticated,
  administrator,
  request
})