import React from 'react';
import { connect } from 'react-redux';
import { showWindow } from 'domain/actions/restaurants.action';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { IGallery } from 'domain/types/restaurants.type';

interface IProps {
  image: IGallery | null;
  deleteImageGallery: (image: IGallery) => void;
}

const DeleteImageWin: React.FC<IProps> = (props: any) => {
  const { image, show, showWindow, deleteImageGallery } = props;

  const accept = () => deleteImageGallery(image)
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('deleteImage', false)} 
      message={`Вы действительно хотите удалить картинку?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.restaurants.windows.deleteImage,
})

export default connect(mapStateToProps, {showWindow})(DeleteImageWin)