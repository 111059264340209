import React, { useEffect, useState } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IUser } from "domain/types/users.type";
import { Rating } from "primereact/rating";
import styles from "../styles.module.css";
import ActionsColumn from "pages/views/components/table/action.column";
import { getReviews, showWindow } from "domain/actions/settings.action";
import { IReview } from "domain/types/settings.type";
import DeleteReviewWin from './windows/delete.review.window';
import UpdateReviewWin from "./windows/update.review.window";
import ErrorReviewWin from "./windows/error.review.window";
import { toDate } from "utils/toDate.util";
import { useMediaQuery } from "react-responsive";


export const ReviewsUserPage: React.FC = (props: any) => {
  const [ current, setCurrent ] = useState<IReview | null>(null)
  const { 
    reviews, 
    loading, 
    getReviews,  
    showWindow 
  } = props;

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleRefresh = () => getReviews();
  const handleViewing = (review: IReview) => {
    setCurrent(review);
    showWindow('review_update', true)
  };
  const handleDelete = (review: IReview) => {
    setCurrent(review);
    showWindow('delete', true)
  }

  let menu = (item: any) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(item),
    },
    {
      label: 'Удалить', 
      icon: 'pi pi-fw pi-trash', 
      command:()=> handleDelete(item) 
    }
  ]

  const nameTemplate = (account: IUser, createdAt: Date) => {
    const name = account?.name ? account.name : null;
    const surname = account?.surname ? account.surname : "";
    const phone = account.phone;

    return (
      <div className="p-d-flex p-flex-column">
        <i>{toDate(createdAt)}</i>
        <strong>{name ? `${name} ${surname}` : "Не указан пользователем"}</strong>
        <i>тел: {phone} </i> 
      </div>
    )
  }

  const reviewTemplate = (rowData: IReview) => {
    const review = rowData.review;
    const balls = rowData.balls;
    const publish = rowData.publish;
    const rejects = rowData.rejects;

    return (
      <div >
        <div className="p-d-flex p-col-12 p-p-0 p-jc-between">
          <Rating cancel={false} stars={5} disabled value={balls} className={styles['rating']}/>
          <span 
            className={`task-status task-status-${publish ? 'new' : 'start'}`}
          >
            {publish ? 'Опубликовано' : 'Не опубликовано'}
          </span>
        </div>
        <p className={styles['review_text'] + " p-my-0"}>{review}</p>

        {rejects.length ? (
          <i style={{fontSize: '12px', color: 'red'}}>Комментарий: {rejects[0].comment}</i>
        ) : <></>}
      </div>
    )
  }

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  useEffect(() => handleRefresh(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <span className="content-header">Отзывы о путеводителе</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <Button label="Обновить" onClick={handleRefresh} className="button-primary-out"/>
          </div>
        </div>

        <div className="card">
          <DataTable 
            value={reviews} 
            scrollable={!isMobile} 
            scrollHeight={!isMobile ? "calc(100vh - 324px)" : ""} 
            responsiveLayout="stack" 
            loading={loading}
            className="table-mobile-rows not-selection-btn"
            breakpoint="768px"
            
          >
            <Column 
                field="account" header="Пользователь"
                body={(rowData)=>nameTemplate(rowData.account, rowData.createdAt)}
                style={{ maxWidth: '15rem' }}
              ></Column>
              <Column 
                field="review" header="Отзыв"
                body={reviewTemplate}
              ></Column>
              <Column body={action} style={{ maxWidth: '5rem' }}></Column>
          </DataTable>
        </div>

      </ScrollPanel>

      <UpdateReviewWin review={current} />
      <ErrorReviewWin review={current} />
      <DeleteReviewWin reviewId={current ? current.id : null} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  reviews: state.settings.reviews,
  loading: state.settings.request.loading
})

export default connect(
  mapStateToProps, 
  { 
    getReviews, 
    showWindow,   
  })(ReviewsUserPage);