import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSecretShopperBidById, createTasks, showWindowBid  } from 'domain/actions/secretShopper.action';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface IProps{
  currentId: string | null;
}

const BidDialog: React.FC<IProps> = (props: any) => {
  const { currentId, visible, showWindowBid, getSecretShopperBidById, current } = props;
  
  useEffect(() => {
    if(currentId) getSecretShopperBidById(currentId)
  }, [getSecretShopperBidById, currentId])

  const handleClose = () => showWindowBid('views', false);
  const handleCreateTasks = () => showWindowBid('createTasks', true);

  return (
    <Dialog header="Просмотр заявки" visible={visible} style={{ width: '50vw' }} onHide={handleClose}>
      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Заведение:</span>
        <span>{current?.restaurant}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Владелец:</span>
        <span>{current?.ownerId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Город:</span>
        <span>{current?.cityId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Район:</span>
        <span>{current?.districtId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Эквайринг:</span>
        <span>{current?.acquiringId}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Стоимость:</span>
        <span>{current?.cost}</span>
      </div>

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <span>Статус заявки:</span>
        <span>{current?.status}</span>
      </div>

      <div className="p-col-12 p-d-flex p-jc-between">
        <Button label="Создать задания" onClick={handleCreateTasks} disabled={current?.restaurant === "Удалено"}/>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.secretShopper.bids.current,
  visible: state.secretShopper.bids.windows.views
})
export default connect(mapStateToProps, { getSecretShopperBidById, createTasks, showWindowBid } )(BidDialog);