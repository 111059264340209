import * as constants from "domain/constants/auth.constant";
import * as types from "domain/types/auth.type";

export function login(values: types.IAuthForm){
  return {
    type: constants.AUTH_SAGA_LOGIN,
    payload: { values }
  }
}

export function logOut(){
  return {
    type: constants.AUTH_SAGA_LOGOUT,
  }
}

export function currentMe(){
  return {
    type: constants.AUTH_SAGA_CURRENT_ME,
  }
}

export function setAuthenticated(isAuthenticated: boolean): types.AuthReduceTypes{
  return {
    type: constants.AUTH_REDUCE_SET_AUTH,
    payload: {isAuthenticated}
  }
}

export function reqAuth(isLoad: boolean): types.AuthReduceTypes{
  return {
    type: constants.AUTH_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}