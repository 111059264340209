import * as constants from "domain/constants/coupons.constant";
import * as types from "domain/types/coupons.type";

// SAGA

export function getCoupons(){
  return {
    type: constants.COUPONS_SAGA_GET,
  }
}

export function createCoupon(coupon: any) {
  return {
    type: constants.COUPONS_SAGA_POST,
    payload: { coupon }
  }
}

export function deleteCoupon(coupon: any) {
  return {
    type: constants.COUPONS_SAGA_DELETE,
    payload: { coupon }
  }
}

// Reduce

export function setCoupons(coupons: Array<types.ICoupon>): types.CouponsReduceTypes{
  return {
    type: constants.COUPONS_REDUCE_SET,
    payload: { coupons }
  }
}

export function setCurrent(coupon: types.ICoupon): types.CouponsReduceTypes{
  return {
    type: constants.COUPONS_REDUCE_SET_CURRENT,
    payload: { coupon }
  }
}

export function showWindow(window: string, isShow: boolean ): types.CouponsReduceTypes{
  return {
    type: constants.COUPONS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqCoupons(isLoad: boolean): types.CouponsReduceTypes{
  return {
    type: constants.COUPONS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}