import * as constants from "../constants/norr.constant";

export function error(message: string): any{
  return {
    type: constants.NORR_SHOW_ERROR,
    payload: { message }
  }
}

export function success(message: string): any{
  return {
    type: constants.NORR_SHOW_SUCCESS,
    payload: { message }
  }
}

export function info(message: string): any{
  return {
    type: constants.NORR_SHOW_INFO,
    payload: { message }
  }
}

export function warn(message: string){
  return {
    type: constants.NORR_SHOW_WARN,
    payload: { message }
  }
}

export function clear(){
  return {type: constants.NORR_SHOW_CLEAR}
}

export function showError(msg: string){
  return {
    type: constants.NORR_SAGA_SHOW_ERROR,
    payload: { msg }
  }
}