import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMediaQuery } from 'react-responsive';
import {  
  getRestaurants,
  setLike,
  setPageRestaurants,
  setSizePageRestaurants,
  showWindow,
} from 'domain/actions/restaurants.action';
import { DebounceInput } from 'react-debounce-input';
import { imageBody } from '../list.restaurant';
import { Paginator } from 'primereact/paginator';
import { ICity } from 'domain/types/city.type';

interface IProps{
  city: ICity | null;
  defaultValue: any;
  onSubmit: (value: any) => void;
}

export const ListRestaurantWindows: React.FC<IProps> = (props: any) => {
  const [ selected, setSelected ] = useState(null);
  const [ listens, setListen ] = useState("");
  const [ first, setFirst ] = useState(1);
  const [ sizePage, setSizePage ] = useState(10)
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const {
    defaultValue,
    city,
    getRestaurants, 
    visible, 
    restaurants, 
    loading, 
    onSubmit,
    showWindow,
    pagination,
    setPageRestaurants, 
    setSizePageRestaurants,
    like,
    setLike,
  } = props;

  useEffect(() => { setLike(listens) }, [listens]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { getRestaurants() }, [like]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(visible) {
      console.log(defaultValue ? defaultValue.name : '');
      setListen(defaultValue ? defaultValue.name : '')
      const _filter = restaurants.filter( (i: any) => i.id === defaultValue?.id )
      setSelected(_filter.length ? _filter[0] : null)
      
    }
  },[visible]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefresh = () => getRestaurants();
  const handleClose = () => showWindow('list_restaurants', false);
  const handleSubmit = () => {
    onSubmit(selected);
    handleClose();
  };
  const onPageChange = (event: any) => {
    setPageRestaurants(event.page + 1);
    setSizePageRestaurants(event.rows);
    setFirst(event.first);
    setSizePage(event.rows);
    handleRefresh();
  }

  return (
    <Dialog header={`Заведения(${city ? city.name : ''})`} visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <>
        <div className="p-col-12">
          <span className="p-float-label p-input-icon-left p-col-12 p-p-0 p-d-block" 
            style={{ width: isMobile ? 'calc(100vw - 76px)' : 'auto' }}
          >
          <i className="pi pi-search" />
          <DebounceInput
            minLength={0}
            value={listens}
            debounceTimeout={1000}
            placeholder="Поиск по заведениям"
            className="p-inputtext p-component p-col-12"
            onChange={event => setListen(event.target.value)} />
          </span>
        </div>
      </>
      <DataTable 
        value={restaurants} 
        scrollable={!isMobile} 
        scrollHeight={!isMobile ? "calc(100vh - 400px)" : ""} 
        responsiveLayout="stack"
        breakpoint="768px" 
        loading={loading}
        className="table-mobile-rows"
        selection={selected} onSelectionChange={e => setSelected(e.value)}
      >
        <Column selectionMode="single" style={{ maxWidth: '2rem' }} exportable={false}></Column>
        <Column header="Заведение" body={imageBody} sortable sortField="name" style={{ maxWidth: '90%' }}></Column>
      </DataTable>
      <Paginator first={first} rows={sizePage} totalRecords={pagination.count} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange}></Paginator>

      <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <Button className="p-col-12 p-md-5 p-xl-4 p-button-danger p-mb-2 p-mb-md-0" label="Отмена" onClick={handleClose}/>
        <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Принять" type="submit" onClick={handleSubmit}/>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.list_restaurants,
  restaurants: state.restaurants.restaurants,
  like: state.restaurants.filters.like,
  pagination: state.restaurants.pagination,
  loading: state.restaurants.request.loading
})
export default connect(mapStateToProps, { 
  showWindow, getRestaurants, setLike, setPageRestaurants, setSizePageRestaurants
} )(ListRestaurantWindows);