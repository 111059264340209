import * as constants from "domain/constants/cities.constant";
import * as types from "domain/types/city.type";

export function getCities(){
  return {
    type: constants.CITIES_SAGA_GET_ALL,
  }
}

export function setCities(cities: Array<types.ICity>): types.CitiesReduceTypes{
  return {
    type: constants.CITIES_REDUCE_SET_ALL,
    payload: { cities }
  }
}

export function reqCities(isLoad: boolean): types.CitiesReduceTypes{
    return {
      type: constants.CITIES_CHECKOUT_REQUEST,
      payload: { loading: isLoad }
    }
  }