import React from "react";
import { connect } from "react-redux";
import { showWindow, postRutubeVideo } from 'domain/actions/restaurants.action';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextField } from "components/form/fields";

export const AddVideoRutubeDialog: React.FC = (props: any) => {
  const { visible, current } = props;
  const { showWindow, postRutubeVideo } = props;
  
  const onSubmit = (values: any) => {
    postRutubeVideo(current.id, values)
  }
  const handleClose = () => showWindow('add_video_rutube', false);

  return (
    <>
      <Dialog header="Добавление видео Rutube" visible={visible} style={{minWidth: '383px'}} onHide={handleClose}>

        <Form
          onSubmit={onSubmit}
          initialValues={null}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className='p-mt-3 p-mb-2'>
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="title"
                  label="Описание"
                  render={TextField}
                  settings={{
                    placeholder: "Краткое описание видео",
                  }}
                />
              </div>

              <div className='p-mt-3 p-mb-2'>
                <Field
                  validate={composeValidators(requiredValidator)}
                  name="videoId"
                  label="Идентификатор видео с Rutube"
                  render={TextField}
                  settings={{
                    placeholder: "ba849e184618cfa6a2bc043622cf2e06",
                  }}
                />
              </div>
              
              <div className="p-mt-1 p-d-flex p-jc-end">
                <Button label="Применить" />
              </div>
            </form>
          )}
        /> 

      </Dialog>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  current: state.restaurants.current.restaurant,
  visible: state.restaurants.windows.add_video_rutube
})

export default connect(mapStateToProps, { showWindow, postRutubeVideo })(AddVideoRutubeDialog);