import React, { useState } from "react";

import { TabView, TabPanel } from 'primereact/tabview';
import ListStocks from "./list.stocks";
import ListCoupons from "./list.coupons";

export const Stocks: React.FC = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Список Акций">
          <ListStocks />
        </TabPanel>
        <TabPanel header="Список Купонов">
          <ListCoupons />
        </TabPanel>
      </TabView>
    </>
  );
}

export default Stocks;