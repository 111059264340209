import React, { useEffect, useState } from 'react';

import { IQuestion } from 'domain/types/secretShopper.type';

import {Checkbox} from 'primereact/checkbox';
import { groupBy, orderBy } from 'lodash';
import { toCategoryQuestion } from '../table.bodies';

interface IProps {
  questions: IQuestion[];
  selected: IQuestion[];
  setSelected: (e: any) => void;
  filter?: any
}

const GroupQuestions: React.FC<IProps> = (props: IProps) => {
  const { questions, selected, setSelected, filter = null } = props;
  const [ groups, setGroups ] = useState<any>({});

  useEffect(() => {
    refresh(questions)
  }, [questions, selected, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = (questions: IQuestion[]) => {
    const _filter = filter ? [] : questions;
    if(filter) {
      questions.forEach( (q:any) => {
        if(q.subcategory.map( (i:any) => i.categoryId).includes(filter)) _filter.push(q)
      } )
    }

    const _questions = _filter.map( (q) => {
      const check = selected.filter((item) => item.id === q.id)
      return { ...q, priority: check.length ? check[0].priority : 9999 }
    } )
    setGroups(groupBy(_questions, 'category'))
  }

  const onChange = (e:any) => {
    let _selectedQuestions = [...selected];

    if (e.checked) {
      const index = orderBy(groups[e.value.category], ['priority'], ['asc']).indexOf(e.value)
      
      _selectedQuestions.push({ ...e.value, priority: index });
    }else {
      for (let i = 0; i < _selectedQuestions.length; i++) {
        const selectedQuestion = _selectedQuestions[i];

        if (selectedQuestion.id === e.value.id) {
          _selectedQuestions.splice(i, 1);
          break;
        }
      }
    }

    setSelected( _selectedQuestions );
  }

  const priorityUp = (id:string) => {
    let _selectedQuestions = [...selected];

    const _index = _selectedQuestions.map( (q) => q.id).indexOf(id)
    let elem = _selectedQuestions[_index] as IQuestion
  
    if(elem) {
      const orders = orderBy(groups[elem.category], ['priority'], ['asc'])

      orders.forEach( (q, index) => {
        if(q.id === id && index > 0){
          if(elem?.priority && elem.priority > 0) {
            elem.priority = index - 1;
            const next = orders[index-1]
            const elIndex = _selectedQuestions.map( (q) => q.id).indexOf(next.id)
            if(elIndex > -1) _selectedQuestions[elIndex].priority = index;
          }

        }
      })
      
      setSelected( _selectedQuestions );
      refresh(questions)
    }
    
  }

  const priorityDown = (id:string) => {
    let _selectedQuestions = [...selected];

    const _index = _selectedQuestions.map( (q) => q.id).indexOf(id)
    let elem = _selectedQuestions[_index] as IQuestion
  
    if(elem) {
      const orders = orderBy(groups[elem.category], ['priority'], ['asc'])

      orders.forEach( (q, index) => {
        if(q.id === id){
        
          elem.priority = index + 1;
          const next = orders[index+1]
          const elIndex = _selectedQuestions.map( (q) => q.id).indexOf(next?.id)
          if(elIndex > -1) _selectedQuestions[elIndex].priority = index;

        }
      })
      
      setSelected( _selectedQuestions );
      refresh(questions)
    }
    
  }

  const renderCategory = Object.keys(groups).map((key) => {
    return (
      <div className="p-col-12" key={key}>
        <h4 className="p-m-2">{toCategoryQuestion(key)}</h4>

        <div className="p-d-flex p-flex-wrap" >
          {orderBy(groups[key], ['priority'], ['asc']).map( (question: IQuestion) => {
            
            const check = selected.some((item) => item.id === question.id)
            
            return (
              <div className="p-p-1 p-col-12" key={question.id}>
                <div className="p-field-checkbox question-style p-jc-between" >
                  <div className='p-d-flex'>
                    <Checkbox 
                      inputId={question.id} 
                      name="category" 
                      value={question} 
                      onChange={onChange} 
                      checked={check}
                      className='p-mr-2 p-as-center'
                    />
                    <label htmlFor={question.id} style={{ cursor: 'pointer'}}>{question.question}</label>
                  </div>

                  <div className={`p-jc-between ${check ? 'p-d-flex' : 'p-d-none'}`}>
                    <div onClick={() => priorityUp(question.id)}>
                      <i 
                        className="pi pi-angle-up p-col-6 p-text-center" 
                        style={{ cursor: 'pointer'}
                      }></i>
                    </div>
                    <div onClick={() => priorityDown(question.id)}>
                      <i 
                        className="pi pi-angle-down p-col-6 p-text-center" 
                        style={{ cursor: 'pointer'}}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            ) 
          } )}
        </div>
      </div>
    );
  });

  return (
    <div className='p-col-12' style={{maxWidth: "768px"}}>
      {renderCategory}
    </div>
  );
};

export default GroupQuestions;
