import React, { useEffect } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { isHTTPS } from 'utils/regexp';

interface IProps {
  myUploader?: (event: any) => void;
  onSelectFIle: (event: any) => void;
  onClear?: () => void;
  label: string,
  file: any, 
  currentImg?: string
}

const SingleUploadImage = React.forwardRef<any, IProps>((props: any, ref: any) => {
  const { label, onSelectFIle, file, currentImg, onClear = () => {}} = props

  const itemTemplate = (file: any, props: any) => {
    // console.log(currentImg)
    const template = (
      <div className="p-d-flex p-ai-center p-flex-wrap p-p-0">
        <img alt={file.name} role="presentation" src={file.objectURL} style={{width: '100%'}}/>
      </div>
    )
    const src=`${isHTTPS(currentImg) ? currentImg : '/api/files/' + currentImg}`
    const currentTemplate = (
      <div className="p-d-flex p-ai-center p-flex-wrap p-p-0">
        <img alt="Логотип" role="presentation" src={src} style={{width: '100%'}}/>
      </div>
    )

    return !!currentImg && !file ? currentTemplate : template
  }
  
  useEffect(() => {
    if(ref && ref.current) ref.current.setState({files: [file]})
  } , [ref, file])

  const chooseOptions = 
    {icon: 'pi pi-fw pi-images', iconOnly: false, className: 'single-choose-btn p-button-rounded p-button-outlined p-my-1', label: 'Загрузить'};
  const cancelOptions = 
    {icon: 'pi pi-fw pi-times', iconOnly: false, className: 'single-choose-btn p-button-danger p-button-rounded p-button-outlined', label: 'Очистить' };

  const headerTemplate = (options:any) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div className={className + ' p-flex-column'} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {cancelButton}
      </div>
    );
  }

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <span>{label}</span>
      </div>
    )
  }

  return (
    <div className="galleria single-uploader">
      <FileUpload ref={ref} name="image" url="" onUpload={()=>{}} accept="image/*" maxFileSize={20000000} customUpload multiple={false}
        headerTemplate={headerTemplate} chooseOptions={chooseOptions} cancelOptions={cancelOptions} itemTemplate={itemTemplate} onSelect={ (e:any) => {
          console.log(e)
          e.originalEvent.preventDefault();
          if(e.files.length) onSelectFIle(e.files[0]);
        }} emptyTemplate={emptyTemplate}
        onClear={onClear}
      />
    </div>
  );
});


export default SingleUploadImage;