import { combineReducers } from 'redux'
import * as constants from "domain/constants/stocks.constant";
import * as types from "domain/types/stocks.type";
import { IStatusResponse } from 'domain/types/static.type';
import { ICity } from 'domain/types/city.type';


const initialState = {
  stocks: [],
  current: null,
  filters: {
    cities: [],
    state: [],
    like: ""
  },
  windows: { 
    add: false,
    update: false,
    delete: false, 
    filters: false,
  },
  request: {
    loading: false,
    error: null
  }
};

export function stocks(state = initialState.stocks, action: types.StocksReduceTypes): any {
  switch (action.type) {
    case constants.STOCKS_REDUCE_SET:
      return action.payload.stocks;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.StocksReduceTypes): any {
  switch (action.type) {
    case constants.STOCKS_REDUCE_SET_CURRENT:
      return action.payload.stock;
    default:
      return state;
  }
}

export function filters(inState = initialState.filters, action: types.StocksReduceTypes): any {
  switch (action.type) {
    case constants.STOCK_REDUCE_SET_FILTERS:
      const {cities, state } = action.payload;
      return {
        ...inState,
        cities: cities ? cities : [],
        state: state ? state : []
      }
    default:
      return inState;
  }
}

export function request(state = initialState.request, action: types.StocksReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.STOCKS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.StocksReduceTypes): any {
  switch (action.type) {
    case constants.STOCKS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function getStock(state: { stocks: { current: types.IStock } }) {
  return state.stocks.current.restaurant
}

export function getFiltersStocks(state: 
  { stocks: 
    { filters: { cities: ICity[], like: string } } } ) {
  return state.stocks.filters
}

export default combineReducers({
  stocks,
  current,
  filters,
  request,
  windows
})