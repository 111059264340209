import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { Outlet } from "react-router-dom";


export const Settings: React.FC = () => {
  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Настройки</h1>
        
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default Settings;