import React, { useState } from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { Header, SidebarComponent } from "../components";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "domain/actions/auth.action";

export const Panel: React.FC = (props: any) => {
  const { logOut, account, currentUser } = props;

  const navigate = useNavigate();
  const [ visSidebar, setVisSidebar ] = useState(true);
  const [ toggled, setToggle ] = useState(false);

  const handleLogotype = () => navigate("/")
  const handleLogout = () => logOut();
  
  const handleSidebar = () => {
    setVisSidebar(!toggled ? false : !visSidebar)
    setToggle(true)
  };
  const handleToggleSidebar = () => {
    setVisSidebar(true)
    setToggle(!toggled)
  }

  return (
    <PrivateRoute>
      <>
        <Header 
          handleSidebar = { handleSidebar }
          handleLogotype = { handleLogotype } 
          handleLogout = { handleLogout }
          currentUser={currentUser}
          account={account} 
        />

        <div className="private-main">
          <SidebarComponent 
            collapsed={visSidebar}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />

          <div className="p-p-2 main-page">
            <Outlet />
          </div>

        </div>
      </>
    </PrivateRoute>
  );
}

export default connect(null, {logOut})(Panel);