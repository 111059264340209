import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showWindow } from 'domain/actions/billing.action';

import { Dialog } from "primereact/dialog";
import { setSchema } from "domain/actions/secretShopper.action";
import { WrapperSpinner } from "components/static";
import { toDateNoTime } from "utils/toDate.util";
import OrderView from "./order.layouts/order.view";


export const OrderWindows: React.FC = (props: any) => {
  const { visible, current, progress } = props;
  const { showWindow, setSchema } = props;

  const handleClose = () => {showWindow('view', false);};

  useEffect( () => {
    if(!visible) {
      setSchema(null);
    }
  }, [visible] ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog 
        header={`Счет от ${current ? toDateNoTime(current?.coupon?.order.createdAt) : '-'}`} 
        visible={visible} 
        style={{minWidth: "375px", fontSize: "14px"}} 
        onHide={handleClose}
      >
        <WrapperSpinner progress={progress} >
          <OrderView />
        </WrapperSpinner>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.billing.windows.view,
  current: state.billing.current,
  progress: state.billing.request.loading
})

export default connect(mapStateToProps, { 
  showWindow, setSchema
})(OrderWindows);