import { toCategoryQuestion, toTypeQuestion } from "./table.bodies";

export const toTypeQuestionTemplate = (type: string) => <span className={`question-type question-type-${type}`}>{toTypeQuestion(type)}</span>
export const toCategoryQuestionTemplate = (category: string) => <span className={`question-type question-category-${category}`}>{toCategoryQuestion(category)}</span>
export const toCategorySchemaTemplate = (category: string) => {
  let eng = 'default';
  if(category === "Рестораны") eng = 'restaurants';
  if(category === "Кофейни") eng = 'coffee';
  if(category === "Кальянные") eng = 'hookah';

  return <span className={`question-type schema-category-${eng}`}>{category}</span>
}