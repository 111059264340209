import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCategoriesHookah, showWindow } from 'domain/actions/restaurants.action';

import { ICategory } from "domain/types/restaurants.type";
import { DataTable } from "primereact/datatable";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import EditCategoryWin from "./edit.category.window";
import AddCategoryWin from "./add.category.window";

export const CategoryHookah: React.FC = (props: any) => {
  const { loading, categories, getCategoriesHookah, showWindow } = props;
  const [selected, setSelected] = useState<ICategory | null>(null);

  useEffect(() => getCategoriesHookah(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAdd = () => showWindow('addCategoryHookah', true);
  const handleRefresh = () => getCategoriesHookah();
  const handleEdit = (item: ICategory) => {
    showWindow('editCategoryHookah', true)
    setSelected(item)
  };

  let menu = (item: ICategory) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-check-circle', 
      command:()=> handleEdit(item)
    }
  ]

  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-pl-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text p-ml-1"/>
          </div>
        </div>

        <div className="card">
        <DataTable value={categories} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
          <Column field="name" header="Название"></Column>
          <Column field="description" header="Описание"></Column>
          <Column body={action} style={{ maxWidth: '5rem' }}></Column>
        </DataTable>
        </div>

      </ScrollPanel>

      <AddCategoryWin />
      <EditCategoryWin selected={selected} />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.hookah.categories,
  loading: state.restaurants.request.loading
})

export default connect(mapStateToProps, { getCategoriesHookah, showWindow })(CategoryHookah);