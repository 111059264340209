import React from "react";
import { IRestaurant, IRestaurantBidUpdate } from "domain/types/restaurants.type";
import { MergeString } from "components/mergeString";
import { MergeGallery } from "components/mergeGallery";
import { MergeCategories } from "components/mergeCategories";
import { connect } from "react-redux";

interface IProps{
  restaurant: IRestaurant | null;
  bid: IRestaurantBidUpdate | null;
}

export const BidUpdateContent: React.FC<IProps> = (props: any) => {
  const { restaurant, bid, categories } = props;

  const classMergeString = "p-d-flex p-flex-column p-col-12 p-md-6";

  return (
    <>
      {restaurant && bid && 
        <>
          <div className="p-col-12 p-p-0">
            <span className="content-header">Основная информация</span>
            
            <div className="p-d-flex p-flex-wrap">
              <MergeString className={classMergeString} label="Наименование:" value={restaurant.name} mergeValue={bid.main?.name} />
              <MergeCategories 
                className="p-d-flex p-flex-wrap p-col-12" 
                categories={categories} 
                currentCategories={restaurant.categories} 
                mergeCategories={bid.categories} 
              />
              <MergeString className={classMergeString} label="Город:" value={restaurant.city} mergeValue={bid.main?.city?.name} />
              <MergeString className={classMergeString} label="Адрес:" value={restaurant.address} mergeValue={bid.main?.address} />
              <MergeString className={classMergeString} label="Телефон заведения:" value={restaurant.telephone} mergeValue={bid.main?.telephone} />
              <MergeString className="p-d-flex p-flex-column p-col-12 " label="Описание:" value={restaurant.description} mergeValue={bid.main?.description} />
            </div>
          </div>

          <div className="p-col-12 p-p-0">
            <span className="content-header">Дополнительная информация</span>
            
            <div className="p-d-flex p-flex-wrap">
              <MergeString className={classMergeString} label="Телефон владельца:" value={restaurant.addInfo.phoneOwner} mergeValue={bid.addInfo?.phoneOwner} />
              {/* <MergeString className={classMergeString} label="Мин. цена:" value={restaurant.addInfo.minPriceHookah} mergeValue={bid.addInfo?.minPriceHookah} /> */}
              <MergeString className={classMergeString} label="Адрес(Вход):" value={restaurant.addInfo.addressEntry} mergeValue={bid.addInfo?.addressEntry} />
            </div>
          </div>

          <div className="p-col-12 p-p-0">
            <span className="content-header">Галерея</span>
            
            <div className="p-d-flex p-flex-wrap">
              <MergeGallery className="p-d-flex p-flex-wrap" gallery={restaurant.gallery} mergeGallery={bid.gallery} />
            </div>
          </div>

          <div className="p-col-12 p-p-0">
            <span className="content-header">Часы работы</span>
            
            <div className="p-d-flex p-flex-wrap">
              <MergeString className={classMergeString} label="Пн:" value={restaurant.workingHours.monday} mergeValue={bid.working?.monday} />
              <MergeString className={classMergeString} label="Вт:" value={restaurant.workingHours.tuesday} mergeValue={bid.working?.tuesday} />
              <MergeString className={classMergeString} label="Ср:" value={restaurant.workingHours.wednesday} mergeValue={bid.working?.wednesday} />
              <MergeString className={classMergeString} label="Чт:" value={restaurant.workingHours.thursday} mergeValue={bid.working?.thursday} />
              <MergeString className={classMergeString} label="Пт:" value={restaurant.workingHours.friday} mergeValue={bid.working?.friday} />
              <MergeString className={classMergeString} label="Сб:" value={restaurant.workingHours.saturday} mergeValue={bid.working?.saturday} />
              <MergeString className={classMergeString} label="Вс:" value={restaurant.workingHours.sunday} mergeValue={bid.working?.sunday} />
            </div>
          </div>

          <div className="p-col-12 p-p-0">
            <span className="content-header">Социальные сети</span>
            
            <div className="p-d-flex p-flex-wrap">
              <MergeString className={classMergeString} label="facebook:" value={restaurant.social.facebook} mergeValue={bid.social?.facebook} />
              <MergeString className={classMergeString} label="instagram:" value={restaurant.social.instagram} mergeValue={bid.social?.instagram} />
              <MergeString className={classMergeString} label="telegram:" value={restaurant.social.telegram} mergeValue={bid.social?.telegram} />
              <MergeString className={classMergeString} label="twitter:" value={restaurant.social.twitter} mergeValue={bid.social?.twitter} />
              <MergeString className={classMergeString} label="vk:" value={restaurant.social.vk} mergeValue={bid.social?.vk} />
              <MergeString className={classMergeString} label="youtube:" value={restaurant.social.youtube} mergeValue={bid.social?.youtube} />
            </div>
          </div>

        </>
      }
    </>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories
})

export default connect(mapStateToProps)(BidUpdateContent);