import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showWindow, getOwners, getClients, mergeOwner } from 'domain/actions/users.action';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toFioTemplate } from '../components/fio';

interface IProps{
  type: string;
  defaultValue: any;
  onSubmit: (value: any) => void;
}

export const ListUserWindows: React.FC<IProps> = (props: any) => {
  const [ options, setOptions ] = useState([]);
  const [ selected, setSelected ] = useState(null);
  const [ typeName, setTypeName ] = useState<string>('Полный список')
  const {
    defaultValue,
    type, 
    visible, 
    clients, 
    owners, 
    loading, 
    onSubmit,
    showWindow,
    getOwners,
    getClients 
  } = props;

  useEffect(() => {
    if(type === 'owners') return setTypeName('Владельцы')
    if(type === 'manager') return setTypeName('Управляющие')
    if(type === 'clients') return setTypeName('Посетители')
    if(type === 'all') return setTypeName('Полный список')
  }, [type])

  useEffect(() => {
    if(type === 'owners' || type === 'manager') {
      getOwners()
    }
    if(type === 'clients') return getClients()
    if(type === 'all') {
      getOwners();
      getClients();
    }
    
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(type === 'owners') return setOptions(owners.filter( (user: any) => user.role === 'owner'))
    if(type === 'manager') return setOptions(owners.filter( (user: any) => user.role === 'manager'))
    if(type === 'clients') return setOptions(clients)
    if(type === 'all') return setOptions(owners.concat(clients))
  }, [type, clients, visible]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(visible) {
      const _filter = options.filter( (i: any) => i.id === defaultValue?.id )
      setSelected(_filter.length ? _filter[0] : null)
    }
  },[visible, defaultValue, options]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => showWindow('list_users', false);
  const handleSubmit = () => {
    onSubmit(selected);
    handleClose();
  };

  return (
    <Dialog header={`Пользователи(${typeName})`} visible={visible} style={{ minWidth: '50vw' }} onHide={handleClose}>
      <DataTable 
        value={options} 
        scrollable 
        scrollHeight="calc(100vh - 310px)" 
        responsiveLayout="scroll" loading={loading}
        selection={selected} onSelectionChange={e => setSelected(e.value)}
      >
        <Column selectionMode="single" style={{ maxWidth: '2rem' }} exportable={false}></Column>
        <Column field="fio" header="ФИО" body={(rowData)=>toFioTemplate(rowData)} 
          sortable sortField="fio"
        ></Column>
      </DataTable>

      <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-between">
        <Button className="p-col-12 p-md-5 p-xl-4 p-button-danger p-mb-2 p-mb-md-0" label="Отмена" onClick={handleClose}/>
        <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Принять" type="submit" onClick={handleSubmit}/>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  owners: state.users.owners,
  clients: state.users.clients,
  visible: state.users.windows.list_users,
  loading: state.users.request.loading
})
export default connect(mapStateToProps, { showWindow, getOwners, getClients, mergeOwner } )(ListUserWindows);