import React from "react";
import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { TabView, TabPanel } from 'primereact/tabview';

import Clients from './clients';
import Owners from './owners';

export const Users: React.FC = () => {
  return (
    <PrivateRoute>
      <>
        <span className="content-header">Пользователи</span>

        <TabView>
          <TabPanel header="Владельцы">
            <Owners />
          </TabPanel>
          <TabPanel header="Клиенты">
            <Clients />
          </TabPanel>
        </TabView>
      </>
    </PrivateRoute>
  );
}

export default Users;