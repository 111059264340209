import { INotification, NotificationSagaTypes, NotificationReduceTypes } from "domain/types/notification.type";
import * as constants from "../constants/notification.constant";

export function sendNotificationToAll(data: INotification): NotificationSagaTypes{
  return {
    type: constants.NOTIFICATION_SAGA_SEND_ALL,
    payload: data
  }
}

export function getNotifications(): NotificationSagaTypes{
  return {
    type: constants.NOTIFICATION_SAGA_GET_SUBSCRIBE
  }
}

export function uploadXls(file: any) {
  return {
    type: constants.NOTIFICATION_SAGA_UPLOAD_XLS,
    payload: { file }
  }
}

export function setNotifications(data: any): NotificationReduceTypes{
  return {
    type: constants.NOTIFICATION_REDUCE_SET_SUBSCRIBE,
    payload: { data }
  }
}

export function setDataParse(data: any): NotificationReduceTypes{
  return {
    type: constants.NOTIFICATION_REDUCE_SET_DATA_PARSE,
    payload: { data }
  }
}