import React, { useEffect, useState } from 'react';
import { KladrField, MaskField, TextField } from 'components/form/fields';
import { 
  composeValidators, 
  phoneValidator, 
  requiredValidator, 
  fullAddressValidator
} from 'components/form/validators';
import AddingPhones from "./add.phones";

import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { IPhone } from 'domain/types/restaurants.type';

interface IProps {
  city?: string | null;
  phones: IPhone[];
  setPhone: (e: any) => void;
}

const ContactsForm: React.FC<IProps> = (props: any) => {
  const {city, phones, setPhone} = props;
  const [ defaultAddress, serAddress ] = useState("");

  useEffect(() => {
    if(city) serAddress(city)
  }, [city])
  
  return (
    <div className="card-mini p-mt-2">

      <div className="p-d-flex p-flex-wrap">

        <div className="p-col-12 p-md-6">
          <Field
            validate={composeValidators(phoneValidator)}
            name="telephone"
            label="Телефон:"
            render={MaskField}
            settings={{
              placeholder: "Введите номер телефона заведения",
              mask: "+00000000000",
            }}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Field
            name="addInfo.metro" label="Станции метро:" render={TextField}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Field
            validate={composeValidators(phoneValidator)}
            name="addInfo.phoneOwner"
            label="Телефон владельца:"
            render={MaskField}
            settings={{
              placeholder: "Введите номер телефона владельца",
              mask: "+00000000000",
            }}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Field
            name="addInfo.phoneComment" label="Комментарий:" render={TextField}
            settings={{
              placeholder: "Комментарий к номеру владельца",
            }}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Field
            validate={composeValidators(requiredValidator, fullAddressValidator)}
            name={city ? "newCity" : "city"}
            label="Адрес:"
            render={KladrField}
            settings={{
              defaultQuery: defaultAddress,
              placeholder: "Введите адрес заведения",
            }}
          />
        </div>

        <div className="p-col-12 p-md-6">
          <Field
            name="addInfo.addressEntry" label="Вход:" render={TextField}
          />
        </div>

      </div>

      <AddingPhones phones={phones} setPhone={setPhone}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories
})

export default connect(mapStateToProps)(ContactsForm);