import React, { useEffect } from "react";
import { connect } from "react-redux";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { getSchemas, showWindowSchema, showSchema, deleteSchema, getQuestions, getShopperCategories } from "domain/actions/secretShopper.action";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActionsColumn from "pages/views/components/table/action.column";
import SchemaWindow from "./windows/schema.window";
import { toCategorySchemaTemplate } from "./toBodies";
import { useMediaQuery } from "react-responsive";

export const Schemas: React.FC = (props: any) => {
  const { 
    subcategories,
    schemas, 
    getSchemas, 
    getQuestions, 
    showWindowSchema, 
    showSchema, 
    deleteSchema, 
    getShopperCategories 
  } = props;
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  useEffect(() => { getSchemas() }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { getQuestions() }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => { return getShopperCategories() }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateQuestion = () => showWindowSchema('views', true);
  const handleRefresh = () => getSchemas();
  const handleViewing = (id: string) => showSchema(id);
  const handleDelete = (id: string) => deleteSchema(id);

  let menu = (item: { id: string; }) => [
    {label: 'Просмотр', icon: 'pi pi-fw pi-pencil', command:()=> handleViewing(item.id) },
    {label: 'Удалить', icon: 'pi pi-fw pi-trash', command:()=> handleDelete(item.id) }
  ]
  const action = (rowData: any) =>
    <ActionsColumn menuItems={menu(rowData)} element={rowData} />

  const renderHeader = () => {
    return (
      <div className="p-d-flex">
        <Button 
          label="Создать" 
          onClick={handleCreateQuestion} 
          className="p-button-raised p-button-success p-button-text p-mr-2"
        />
        <Button 
          label="Обновить" 
          onClick={handleRefresh} 
          className="button-primary-out"
        />
      </div>
    )
  }
  const header = renderHeader();
  
  return (
    <div className="content">

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 245px)' }}>
        <div className="card">
          <DataTable 
            value={schemas} 
            header={header}
            scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
            responsiveLayout="stack" breakpoint="768px" stripedRows
            className="table-mobile-rows"
          >

            <Column 
              field="name" header="Наименование"
              sortable sortField="name"
            ></Column>

            <Column 
              header="Тип анкеты" body={(rowData) => {
                const _filter = subcategories.filter( (r: any) => r.id === rowData.categoryId)
                if(_filter.length) return toCategorySchemaTemplate(_filter[0].name);
                return <></>
              }}
              sortable sortField="type" 
              style={{ maxWidth: '10rem' }}
            ></Column>
            
            
            <Column body={action} style={{ maxWidth: '5rem' }}></Column>
          </DataTable>
        </div>
      </ScrollPanel>

      <SchemaWindow />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  schemas: state.secretShopper.schemas.data,
  subcategories: state.secretShopper.category,
})

export default connect(
  mapStateToProps, 
  {
    getQuestions,
    getSchemas,
    showWindowSchema, 
    showSchema,
    deleteSchema,
    getShopperCategories
  } 
)(Schemas);