import React from 'react';
import { connect } from "react-redux";

import { taskComplete } from 'domain/actions/secretShopper.action';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { requiredValidator } from 'components/form/validators';
import { MaskField } from 'components/form/fields';


export const SumReceiptWindow: React.FC<any> = (props: any) => {
  const { taskComplete, id, show, setShow } = props;

  const onSubmit = (val: any) => {
    taskComplete(id, val.sum)
    setShow(false)
  }

  return (
    <Dialog visible={show} style={{minWidth: '383px'}} onHide={()=>setShow(false)} header="Сумма чека">
      <Form
        onSubmit={onSubmit}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="p-col-12 p-p-0">
            <Field
              validate={requiredValidator}
              name="sum"
              label="Сумма:"
              render={MaskField}
              settings={{
                placeholder: 'Введите сумму указанную в чеке',
                mask: "000000"
              }}
            />
            <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-end">
              <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Отправить" type="submit"/>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default connect(
  null, 
  { taskComplete }
)(SumReceiptWindow);