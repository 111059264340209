import { combineReducers } from 'redux'
import * as constants from "domain/constants/notification.constant";
import * as types from "domain/types/notification.type";
import { IStatusResponse } from 'domain/types/static.type';


const initialState = {
  subscriptions: [],
  current: null,
  dataParser: [], 
  windows: { 

  },
  request: {
    loading: false,
    error: null
  }
};

export function subscriptions(state = initialState.subscriptions, action: types.NotificationReduceTypes): any {

  switch (action.type) {    
    case constants.NOTIFICATION_REDUCE_SET_SUBSCRIBE:
      return action.payload.data;
    default:
      return state;
  }
}


export function dataParser(state = initialState.dataParser, action: types.NotificationReduceTypes): any {

  switch (action.type) {    
    case constants.NOTIFICATION_REDUCE_SET_DATA_PARSE:
      return action.payload.data;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.NotificationReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.NOTIFICATION_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.NotificationReduceTypes): any {
  switch (action.type) {
    case constants.NOTIFICATION_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export default combineReducers({
  subscriptions,
  dataParser,
  request,
  windows
})