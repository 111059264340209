import * as constants from "domain/constants/stocks.constant";
import * as types from "domain/types/stocks.type";

// SAGA

export function getStocks(){
  return {
    type: constants.STOCKS_SAGA_GET,
  }
}

export function createStocks(stock: any) {
  return {
    type: constants.STOCKS_SAGA_POST,
    payload: { stock }
  }
}

export function deleteStock(stock: any) {
  return {
    type: constants.STOCKS_SAGA_DELETE,
    payload: { stock }
  }
}

// Reduce

export function setStocks(stocks: Array<types.IStock>): types.StocksReduceTypes{
  return {
    type: constants.STOCKS_REDUCE_SET,
    payload: { stocks }
  }
}

export function setCurrent(stock: types.IStock): types.StocksReduceTypes{
  return {
    type: constants.STOCKS_REDUCE_SET_CURRENT,
    payload: { stock }
  }
}

export function showWindow(window: string, isShow: boolean ): types.StocksReduceTypes{
  return {
    type: constants.STOCKS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqStocks(isLoad: boolean): types.StocksReduceTypes{
  return {
    type: constants.STOCKS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}