import moment from "moment";

export function toDate(date) {
  if (!date) return "-";

  return moment(date).format("DD MMM YYYY HH:mm:ss");
}

export function toDateNoTime(date) {
  if (!date) return "-";

  return moment(date).format('ll');
}

export function toTime(date) {
  if (!date) return "-";

  return moment(date).format("hh:mm:ss");
}