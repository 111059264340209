import { isHTTPS } from "utils/regexp";

export const toFioTemplate = (user: any) => (
  <div className="p-d-flex p-ai-center">
    <img 
      alt={user.fio} 
      src={`${isHTTPS(user.avatar) 
        ? user.avatar 
        : user.avatar 
          ? '/api/files/' + user.avatar
          : '/api/admin/files/default'
        }`}
      width={32}
      height={32}
      style={{ verticalAlign: 'middle', borderRadius: '50%', objectFit: 'cover' }} 
      className="p-mr-2"
    />
    
    <span > <strong>{user.fio !== " " ? user.fio : 'Не указано'}</strong> (<i>tel:{user.phone}</i>)</span>
  </div>
);