import React from "react";
import { connect } from "react-redux";

import { PrivateRoute } from "pages/routes/config/TypeRoute";
import { buyCoupons } from "domain/actions/billing.action";

import { Outlet } from "react-router-dom";

export const Billing: React.FC = (props: any) => {

  return (
    <PrivateRoute>
      <>
        <h1 style={{margin: '0px'}}>Биллинг(бета)</h1>
        <Outlet />
      </>
    </PrivateRoute>
  );
}

export default connect(null, {buyCoupons})(Billing);