import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { getNotifications, uploadXls } from "domain/actions/notification.action";
import { FileUpload } from "primereact/fileupload";

export const EmailNotificationPage: React.FC = (props: any) => {
  const [ file, setSelectFIle ] = useState<any>(null)

  const {uploadXls, data, loading} = props;

  const handleUpload = () => uploadXls(file);

  return (
    <>
      <span className="content-header">E-mail - рассылка</span>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 190px)' }}>

        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
            <FileUpload 
              mode="basic" 
              name="xls"
              accept="xlsx/xls" 
              auto
              // url="/api/admin/parser/xls"
              onSelect={ (e:any) => {
                if(e.files.length) setSelectFIle(e.files[0]);
              }}
              onBeforeUpload={() => {
                handleUpload()
              }}
              chooseLabel="Загрузить excel" 
            />
          </div>
        </div>

        <div className="card">
          <DataTable value={data} scrollable scrollHeight="calc(100vh - 310px)" responsiveLayout="scroll" loading={loading}>
            <Column 
              field="Название" header="Название"
            ></Column>
            <Column 
              field="Email" header="Email"
            ></Column>
            <Column 
              field="Населенный Пункт" header="Населенный Пункт"
            ></Column>
            <Column 
              field="Адрес" header="Адрес"
            ></Column>
          </DataTable>
        </div>

      </ScrollPanel>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  data: state.notification.dataParser,
  loading: state.notification.request.loading
})

export default connect(mapStateToProps, { getNotifications, uploadXls })(EmailNotificationPage);