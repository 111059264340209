import { combineReducers } from 'redux'
import * as constants from "domain/constants/coupons.constant";
import * as types from "domain/types/coupons.type";
import { IStatusResponse } from 'domain/types/static.type';
import { ICity } from 'domain/types/city.type';


const initialState = {
  coupons: [],
  current: null,
  filters: {
    cities: [],
    state: [],
    like: ""
  },
  windows: { 
    add: false,
    update: false,
    delete: false, 
    filters: false,
  },
  request: {
    loading: false,
    error: null
  }
};

export function coupons(state = initialState.coupons, action: types.CouponsReduceTypes): any {
  switch (action.type) {
    case constants.COUPONS_REDUCE_SET:
      return action.payload.coupons;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.CouponsReduceTypes): any {
  switch (action.type) {
    case constants.COUPONS_REDUCE_SET_CURRENT:
      return action.payload.coupon;
    default:
      return state;
  }
}

export function filters(inState = initialState.filters, action: types.CouponsReduceTypes): any {
  switch (action.type) {
    case constants.COUPONS_REDUCE_SET_FILTERS:
      const {cities, state } = action.payload;
      return {
        ...inState,
        cities: cities ? cities : [],
        state: state ? state : []
      }
    default:
      return inState;
  }
}

export function request(state = initialState.request, action: types.CouponsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.COUPONS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.CouponsReduceTypes): any {
  switch (action.type) {
    case constants.COUPONS_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function getCoupon(state: { coupons: { current: types.ICoupon } }) {
  return state.coupons.current.restaurant
}

export function getFiltersCoupons(state: 
  { coupons: 
    { filters: { cities: ICity[], like: string } } } ) {
  return state.coupons.filters
}

export default combineReducers({
  coupons,
  current,
  filters,
  request,
  windows
})