import React, { useState } from "react";

import { TabView, TabPanel } from 'primereact/tabview';
import ListRestaurants from "./list.restaurant";
import BidUpdateRestaurants from "./bid.update.restaurant";

export const Restaurants: React.FC = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Список заведений">
          <ListRestaurants />
        </TabPanel>
        <TabPanel header="Заявки на редактирование">
          <BidUpdateRestaurants />
        </TabPanel>
      </TabView>
    </>
  );
}

export default Restaurants;