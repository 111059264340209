import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IDishes } from 'domain/types/restaurants.type';
import { Button } from 'primereact/button';
import AddDishesWin from '../../kitchen/add.dishes.window';
import { showWindow } from 'domain/actions/restaurants.action';
import EditDishesWin from '../../kitchen/edit.dishes.window';
import { groupBy } from 'lodash';
import CategoryMenu from './category.menu';
import { ScrollPanel } from 'primereact/scrollpanel';

const KitchenForm: React.FC = (props: any) => {
  const [ item, setItem ] = useState<IDishes | null>(null);
  const [menu, setMenu] = useState<any>({});
  const {categories, dishes, showWindow} = props;

  const handleAdd = (e: any) => {
    e.preventDefault();
    showWindow('addDishes', true)
  }

  useEffect(() =>{
    const group = groupBy(dishes, (item) => item.categoryId)
    setMenu(group)
  }, [dishes, categories])

  const renderCategory = Object.keys(menu).map((key) => {
    const category = categories.filter( (category: { id: string; }) => category.id === key)
    return <CategoryMenu key={key} category={category} items={menu[key]} setItem={setItem}/>;
  });

  return (
    <>
      <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success p-mr-1"/>
      
      <ScrollPanel style={{height: 'calc(100vh - 400px)'}}>
        {renderCategory}
      </ScrollPanel>
      
      <AddDishesWin />
      <EditDishesWin item={item} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  dishes: state.restaurants.current.dishes,
  categories: state.restaurants.kitchen.categories
})

export default connect(mapStateToProps, {showWindow})(KitchenForm);