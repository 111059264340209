export function toCategoryQuestion(category: string){
  switch (category) {
    case 'main':
      return 'Основная информация';
    case 'entry':
      return 'Вход';
    case 'farewell':
      return 'Расчёт/прощание';
    case 'impression':
      return 'Впечатления о посещении';
    case 'order':
      return 'Заказ';
    case 'service':
      return 'Обслуживание';
    case 'standard':
      return 'Соответствие стандартам';
    default:
      return category
  }
}

export function toTypeSchema(type: string){
  switch (type) {
    case 'restaurant':
      return 'Ресторан';
    case 'coffee':
      return 'Кофе';
    case 'hookah':
      return 'Кальянная';
    default:
      return type
  }
}

export function toTypeQuestion(type: string){
  switch (type) {
    case 'isOne':
      return 'Один из';
    case 'text':
      return 'Текст';
    case 'area':
      return 'Большой текст';
    default:
      return type
  }
}
