import React, { useEffect } from 'react';
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

interface IProps {
  files: any[];
  setFiles: (event: any) => void;
}

const GalleryForm = React.forwardRef<any, IProps>((props: any, ref: any) => {
  const { setFiles, files } = props;
  
  const onTemplateRemove = (file:any, callback: any) => {
    const index = files.map( (item:any) => item.name).indexOf(file.name)
    if(index > -1) setFiles(files.slice(index));
    callback()
  };

  useEffect(() =>{
    if(ref && ref.current) ref.current.setState({files: files})
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i className="pi pi-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Перетащите сюда файлы для загрузки.</span>
      </div>
    )
  }

  const onSelect = (event: FileUploadSelectParams) => {
    const result = [];
    for (let key in event.files){
      if(typeof event.files[key] === 'object'){ 
        if(files.map( (item:any) => item.name).indexOf(event.files[key].name) === -1){
          result.push(event.files[key])
        }
      }
    }

    setFiles([...files, ...result]);
  }

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap p-p-0">
        <div className="p-d-flex p-ai-center p-p-1 p-col-12 p-md-8" >
          <img alt={file.name} role="presentation" src={file.objectURL} width={120} />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
          </span>
        </div>
        <div className="p-d-flex p-p-1 p-jc-between p-col-12 p-md-4">
          <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
          <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-ml-auto" 
            onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
        
      </div>
    )
  }
  
  const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: ' p-button-success p-button-rounded p-button-outlined'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: ' p-button-danger p-button-rounded p-button-outlined'};

  const headerTemplate = (options:any) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {cancelButton}
      </div>
    );
  }

  return (
    <div className="card-mini p-mt-2">

      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
        <FileUpload ref={ref} name="gallery[]" url="" multiple accept="image/*" 
          className="p-col-12" customUpload uploadHandler={props.myUploader} onSelect={onSelect}
          headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions} cancelOptions={cancelOptions} onClear={() => setFiles([])} />
      </div>
    </div>
  );
});


export default GalleryForm;